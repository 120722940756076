<div
  *ngIf="!!values"
  ngbDropdown
  placement="bottom"
  [container]="appendToBody && 'body'"
  class="wrapper d-inline-block">
  <button
    type="button"
    class="btn"
    [ngClass]="buttonClassName || 'toggle-btn'"
    [class.toggle-btn--readonly]="readonly"
    ngbDropdownAnchor
    [class.dropdown-toggle]="showToggle"
    [class.default]="!itemTemplate"
    (click)="toogle()"
    [disabled]="disabled || readonly">
    <ng-container *ngIf="itemTemplate">
      <ng-container *ngIf="emptySelectionKey && currentSelected === null">
        {{ emptySelectionKey | i18n }}
      </ng-container>
      <ng-template
        *ngIf="!emptySelectionKey || currentSelected !== null"
        [ngTemplateOutlet]="itemTemplate"
        [ngTemplateOutletContext]="{$implicit: currentSelected, head: true}">
      </ng-template>
    </ng-container>
    <ng-container *ngIf="!itemTemplate">
      <span class="tl-dropdown__button-text">
        {{
          emptySelectionKey && currentSelected == null
            ? placeholder
              ? placeholder
              : (emptySelectionKey | i18n)
            : currentSelected
        }}
      </span>
    </ng-container>
  </button>
  <div class="dropdown-menu" ngbDropdownMenu>
    <ng-template
      [ngTemplateOutlet]="contentTemplate"
      [ngTemplateOutletContext]="{$implicit: false}"></ng-template>
  </div>
</div>
<!-- ------------------ templates -------------------- -->
<!--Content template-->
<ng-template #contentTemplate let-modal>
  <tl-scrollable [ngClass]="contentClassName ? contentClassName : ''">
    <div
      *ngIf="emptySelectionKey && emptySelectionAsOption"
      (click)="select(null)"
      class="drop-item">
      <ng-template
        [ngTemplateOutlet]="itemTemplate"
        [ngTemplateOutletContext]="{$implicit: null}">
      </ng-template>
      <a *ngIf="!itemTemplate" class="drop-item-content">
        {{ emptySelectionKey | i18n }}
      </a>
      <span class="icon-ok-icon" *ngIf="modal && currentSelected == null"></span>
    </div>
    <ng-container
      *ngFor="let subSet of values; let index = index; let lastCat = last">
      <div
        *ngIf="!!keyHeadersSet && !!keyHeadersSet[index]"
        class="tl-dropdown__header">
        <div class="tl-dropdown__header-content">
          {{ keyHeadersSet[index] | i18n }}
        </div>
      </div>
      <div
        *ngFor="let data of subSet; let lastItem = last"
        class="drop-item"
        [class.divider]="!lastCat && lastItem"
        (click)="select(data)">
        <ng-template
          [ngTemplateOutlet]="itemTemplate"
          [ngTemplateOutletContext]="{$implicit: data, head: false}">
        </ng-template>
        <a
          *ngIf="!itemTemplate"
          [title]="('global.select' | i18n) + ' ' + data"
          class="drop-item-content">
          {{ data }}
        </a>
        <span class="icon-ok-icon" *ngIf="modal && currentSelected === data"></span>
      </div>
    </ng-container>
  </tl-scrollable>
</ng-template>

<!--Modal template -->
<ng-template #modalTemplate let-c="close" let-d="dismiss">
  <tl-modal-dialog
    [title]="{key: 'global.selectOption'}"
    class="divided"
    [disableContentPadding]="true"
    (closeModal)="c($event)"
    (dismissModal)="d($event)">
    <ng-template
      [ngTemplateOutlet]="contentTemplate"
      [ngTemplateOutletContext]="{$implicit: true}"></ng-template>
  </tl-modal-dialog>
</ng-template>

<!--FullScreen template -->
<ng-template #fullScreenTemplate let-c="close" let-d="dismiss">
  <div class="tl-dropdown__fullscreen">
    <div class="tl-dropdown__fullscreen__header">
      <a class="icon-left-slim-icon" (click)="d($event)"></a>
      <tl-translatable-text
        [text]="{key: 'global.selectOption'}"></tl-translatable-text>
    </div>
    <div class="tl-dropdown__fullscreen__content">
      <ng-template
        [ngTemplateOutlet]="contentTemplate"
        [ngTemplateOutletContext]="{$implicit: true}"></ng-template>
    </div>
  </div>
</ng-template>
