import {Serializable} from 'common';

class TicketOptionsInternal {
  cancellable: boolean;
  deletable: boolean;
  deletableAll: boolean;
  extendable: boolean;
  prizeReturnable: boolean;
  sharable: boolean;
  repeatable: boolean;
  subscribable: boolean;
  proof: boolean;
  archivable: boolean;
}

export class TicketOptions extends Serializable(TicketOptionsInternal) {
  public static createFromBackend(obj: any) {
    let to = new TicketOptions();

    to.cancellable = obj.cancellable;
    to.deletable = obj.deletable;
    to.deletableAll = obj.deletableAll;
    to.extendable = obj.extendable;
    to.sharable = obj.shareable;
    to.repeatable = obj.repetible;
    to.subscribable = obj.abonable;
    to.proof = obj.constancia;
    to.prizeReturnable = obj.returnPrize;
    to.archivable = obj.archivable;

    return to;
  }
}
