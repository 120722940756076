import {AsyncPipe} from '@angular/common';
import {
  ChangeDetectorRef,
  Inject,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {differenceInCalendarDays} from 'date-fns';
import {Observable} from 'rxjs';

import {ENVIRONMENT} from '../../environment/environment-token';
import {TranslationsService} from '../../i18n/translations.service';
import {formatDate} from '../date-fns-wrapper';

@Pipe({name: 'timestampToDayMonthFromNow'})
export class TimestampToDayMonthFromNowPipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  constructor(
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
    cdr: ChangeDetectorRef,
    private translationsService: TranslationsService,
  ) {
    this.asyncPipe = new AsyncPipe(cdr);
  }

  transform(value: number): any {
    if (!value) {
      return '';
    }

    const date = new Date(value);
    let daysDiff = differenceInCalendarDays(date, Date.now());
    let observableToReturn: Observable<string>;
    if (daysDiff === 0) {
      observableToReturn =
        this.translationsService.getTranslation('games.time.today');
    }
    if (daysDiff === 1) {
      observableToReturn =
        this.translationsService.getTranslation('games.time.tomorrow');
    }
    if (observableToReturn) {
      return this.asyncPipe.transform(observableToReturn);
    }
    return formatDate(value, this.environment.locale.dateFormats.dayMonth);
  }

  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }
}
