import {Injectable} from '@angular/core';
import {
  AbstractObservableDataService,
  AlertsService,
  Logger,
  PageableService,
  PaginationDirection,
  TranslatableText,
} from 'common';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  catchError,
  finalize,
  take,
} from 'rxjs';
import {Booth} from './data/booth';
import {BoothFilterDataSource} from './data/booth-filter';
import {BoothsDao} from './data/booths-dao';
import {GeolocationService} from '../geolocation/geolocation.service';
import {GeolocationViewService} from '../geolocation/geolocation-view.service';
import {
  InaccurateLocationError,
  RejectedGeolocationError,
} from '../geolocation/geolocation-errors';

@Injectable()
export abstract class BoothViewDataService
  extends AbstractObservableDataService<Array<Booth>>
  implements PageableService<Array<Booth>>
{
  // UI properties
  emptyLabel: TranslatableText = {
    key: 'administrationOffice.adminSearchEmptyResults',
  };
  get paginationIndex(): number {
    return this.paginationIndex$.value;
  }
  paginationSize: number;
  paginationEnd: boolean;

  dataSource = new BehaviorSubject<Array<Booth>>([]);
  dataSourceSubscription: Subscription;

  get loading(): Observable<boolean> {
    return this.loading$.asObservable();
  }
  protected loading$ = new BehaviorSubject<boolean>(false);

  get geolocationSortEnabled(): boolean {
    return !!this.geolocationBySort$.value;
  }
  get loadingLocation(): Observable<boolean> {
    return this.loadingLocation$.asObservable();
  }
  protected loadingLocation$ = new BehaviorSubject<boolean>(false);

  protected filter$ = new BehaviorSubject<BoothFilterDataSource>(null);
  protected paginationIndex$ = new BehaviorSubject<number>(-1);
  protected data$ = new BehaviorSubject<Array<Booth>>([]);
  protected geolocationBySort$ = new BehaviorSubject<GeolocationPosition>(null);

  constructor(
    protected boothsDao: BoothsDao,
    protected geolocationService: GeolocationService,
    protected geolocationViewService: GeolocationViewService,
    protected logger: Logger,
    protected alertService: AlertsService,
  ) {
    super();
  }

  /** Starts the datasource logic ops and return main observable
   *
   * @param destroyRef Must to pass destroy ref to finalize obserbable dataSource */
  abstract initDataSource(
    destroyRef: Observable<any> | Subject<any>,
    pageSize: number,
  ): Observable<Array<Booth>>;

  loadMore(direction?: PaginationDirection): Observable<Array<Booth>> {
    this.paginationIndex$.next(this.paginationIndex + 1);
    return this.dataSource;
  }

  reset(): Observable<Array<Booth>> {
    this.paginationIndex$.next(-1);
    this.paginationEnd = false;
    this.data$.next([]);
    return this.dataSource;
  }

  getData(): Observable<Array<Booth>> {
    return this.dataSource;
  }

  setData(data: Array<Booth>): void {
    this.data$.next(data);
  }

  setFilter(filterData: BoothFilterDataSource): void {
    this.filter$.next(filterData);
  }

  toogleSortByGeolocation() {
    if (this.geolocationSortEnabled) {
      this.disableSortByGeolocation();
    } else {
      this.enableSortByGeolocation();
    }
  }

  enableSortByGeolocation() {
    this.requestLocationToOrderBooths()
      .pipe(take(1))
      .subscribe((pos: GeolocationPosition) => this.geolocationBySort$.next(pos));
  }

  disableSortByGeolocation() {
    this.geolocationBySort$.next(null);
  }

  protected getFromDao(): Observable<Array<Booth>> {
    this.loading$.next(true);
    return this.boothsDao
      .getBooths(
        {index: this.paginationIndex, pageSize: this.paginationSize},
        this.filter$.value,
      )
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false)),
      );
  }

  protected filterBooth(booth: Booth, filterData: BoothFilterDataSource): boolean {
    return true; //TOFIX: Add actual filtering logic based on the filter criteria
  }

  protected requestLocationToOrderBooths(): Observable<GeolocationPosition> {
    // We need to call requestGeolocation() here cause if fails, this function
    // have to return an error.
    this.loadingLocation$.next(true);
    return this.geolocationViewService.requestLocationToOrderBooths().pipe(
      take(1),
      catchError((geolocationError: RejectedGeolocationError) => {
        if (geolocationError instanceof InaccurateLocationError) {
          throw geolocationError;
        }

        this.logger.warn(
          'Error to request location to order booths',
          geolocationError.message,
        );
        this.alertService.notifyError({key: 'geolocation.geolocationError'});
        throw new RejectedGeolocationError();
      }),
      finalize(() => this.loadingLocation$.next(false)),
    );
  }
}
