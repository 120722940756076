import {Injectable} from '@angular/core';
import {SessionStorage} from './session-storage';

@Injectable()
export class SessionStorageService extends SessionStorage {
  get length(): number {
    return sessionStorage.length;
  }

  clear(): void {
    sessionStorage.clear();
  }

  getItem(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  key(index: number): string | null {
    return sessionStorage.key(index);
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  setItem(key: string, data: string): boolean {
    try {
      sessionStorage.setItem(key, data);
      return true;
    } catch (e) {
      return false;
    }
  }
}
