export const LOCAL_URIS = {
  'https://static.tulotero.net': '/tulotero-static-proxy-global',
  'https://web.es.pre.tulotero.net': 'backend-proxy-es',
  'https://es.pre.tulotero.net': 'backend-proxy-es',
  'https://static.es.stage.tulotero.net': '/tulotero-static-proxy-es',
  'https://web.mx.pre.tulotero.net': 'backend-proxy-mx',
  'https://mx.pre.tulotero.net': 'backend-proxy-mx',
  'https://static.mx.stage.tulotero.net': '/tulotero-static-proxy-mx',
  'https://web.us.stage.tulotero.net': 'backend-proxy-us',
  'https://us.stage.tulotero.net': 'backend-proxy-us',
  'https://static.us.stage.tulotero.net': '/tulotero-static-proxy-us',
  'https://web.pt.stage.tulotero.net': 'backend-proxy-pt',
  'https://pt.stage.tulotero.net': 'backend-proxy-pt',
  'https://static.pt.stage.tulotero.net': '/tulotero-static-proxy-pt',
};
