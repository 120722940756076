import {Serializable} from 'common';

class PromoCardInternal {
  title: string;
  desc: string;
  imgUrl: string;
  buttonText: string;
  buttonUrl: string;
  position: number;
}

export class PromoCard extends Serializable(PromoCardInternal) {
  static createFromBackend(obj: any): PromoCard {
    let promo = new PromoCard();
    promo.title = obj.title;
    promo.desc = obj.desc;
    promo.imgUrl = obj.imgUrl;
    promo.buttonText = obj.buttonText;
    promo.buttonUrl = obj.buttonUrl;
    promo.position = obj.position;

    return promo;
  }
}
