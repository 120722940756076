import {Inject, Injectable} from '@angular/core';
import {ENVIRONMENT, LocalStorage, SessionStorage} from 'common';

@Injectable({
  providedIn: 'root',
})
export class CredentialStorageService {
  private userSelectPersist: boolean;

  constructor(
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
    private localStorage: LocalStorage,
    private sessionStorage: SessionStorage,
  ) {}

  setToken(token: string, persist: boolean): void {
    this.userSelectPersist = persist;
    this.getStorage().setItem(this.environment.localStorageKeys.token, token);
  }

  getToken(): string {
    return this.getStorage().getItem(this.environment.localStorageKeys.token);
  }

  removeToken(): void {
    this.getStorage().removeItem(this.environment.localStorageKeys.token);
    this.userSelectPersist = false;
  }

  private getStorage(): LocalStorage | SessionStorage {
    // When reload, check if the token is saved in session storage or local storage
    // for set userSelectPersist variable
    if (this.userSelectPersist === undefined) {
      this.userSelectPersist = !!this.localStorage.getItem(
        this.environment.localStorageKeys.token,
      );
    }
    // Env allow persist session and user check persist at login or user has old token
    if (
      (this.environment.auth?.persist === true && this.userSelectPersist) ||
      this.userSelectPersist
    ) {
      return this.localStorage;
    } else {
      return this.sessionStorage;
    }
  }
}
