<ol>
  <li
    [attr.id]="step?.id || null"
    class="tl-vertical-stepper__item"
    *ngFor="let step of steps; trackBy: trackByFn"
    [ngClass]="{
      'icon-ok-icon completed': step.completed,
      active: step.active,
      'active-bold': step.boldWhenIsActive
    }">
    <tl-translatable-text [text]="step.text"></tl-translatable-text>
  </li>
</ol>
