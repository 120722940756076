import {Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from 'rxjs';

@Pipe({
  name: 'wrapInObservable',
})
export class WrapInObservablePipe implements PipeTransform {
  transform<T>(value: T): Observable<T> {
    return value instanceof Observable ? value : of(value);
  }
}
