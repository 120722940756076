import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {AbstractNgModel} from '../abstract-ngmodel';
import {ngModelProvider} from '../../model/ng-model-config';

@Component({
  selector: 'tl-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ngModelProvider(InputPasswordComponent)],
})
export class InputPasswordComponent extends AbstractNgModel<string> {
  @HostBinding('class.tl-input-password')
  readonly hostClass = true;

  @ViewChild('nativeInput') nativeInput: ElementRef<HTMLInputElement>;

  @Input()
  ngTabindex: number;

  @Input()
  xs: boolean;

  @Input()
  id = Math.random().toString();

  @Input('value')
  model: string;

  @Input()
  placeholder: string;

  @Input()
  maxlength: number;

  @Input()
  whiteEye: boolean;

  @Output()
  blurred = new EventEmitter<void>();

  inputType: 'password' | 'text' = 'password';

  showPassword = false;

  touched = false;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  onInput(event: any): void {
    this.model = event.target.value;
    this.notify();
    this.markTouched();
  }

  onBlur(): void {
    this.markTouched();
    this.blurred.emit();
  }

  tooglePassword(): void {
    this.showPassword = !this.showPassword;
    if (this.showPassword) {
      this.inputType = 'text';
    } else {
      this.inputType = 'password';
    }
    this.cdr.markForCheck();
  }

  writeValue(value: string) {
    this.model = value;
    if (this.nativeInput) {
      this.nativeInput.nativeElement.value = value;
    }
    this.modelChange(this.model);
  }

  markTouched(): void {
    if (!this.touched) {
      this.modelTouch();
      this.touched = true;
    }
  }

  private notify() {
    this.modelChange(this.model);
  }
}
