// TODO · ADAPTER · remove
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ENVIRONMENT, LocaleService, LocalStorage, Logger} from 'common';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {
  adaptUnfinished,
  patchGameMetadata,
  patchRenderData,
  patchResults,
  patchTickets,
} from '../games/patch/info.patch';

import {BackendDao} from './backend.dao';
import {PrivateInfo} from './private-info/private-info';
import {PublicInfo} from './public-info/public.info';

@Injectable()
export class BackendAdapterDao extends BackendDao {
  constructor(
    protected http: HttpClient,
    protected localStorage: LocalStorage,
    protected locale: LocaleService,
    protected logger: Logger,
    @Inject(ENVIRONMENT) protected environment: Record<string, any>,
  ) {
    super(http, localStorage, locale, logger, environment);
  }

  protected postProcessAllInfoPublic(
    httpObservable: Observable<any>,
  ): Observable<PublicInfo> {
    return httpObservable.pipe(
      switchMap(response => patchGameMetadata(response, this.http)),
      map(response => patchRenderData(response)),
      map(response => patchResults(response)),
      map(response => PublicInfo.createFromBackend(response)),
    );
  }

  protected postProcessAllInfo(
    httpObservable: Observable<any>,
  ): Observable<PrivateInfo> {
    return httpObservable.pipe(
      switchMap(response =>
        response['genericDescriptors']
          ? patchGameMetadata(response, this.http)
          : of(response),
      ),
      map(response => patchRenderData(response)),
      map(response => patchResults(response)),
      map(response => patchTickets(response)),
      map(response => adaptUnfinished(response)),
      map(response =>
        response instanceof HttpResponse
          ? null
          : PrivateInfo.createFromBackend(response),
      ),
    );
  }
}
