import {
  Clonable,
  Country,
  deepClone,
  fixPhoneNumber,
  formatDate,
  parseDate,
  Serializable,
  SerializableProperty,
  SerializableType,
} from 'common';

import {environment} from '~environments/environment';

import {UserExclusion} from './user-exclusion';
import {UserKyc} from './user-kyc';
import {addMinutes, parseISO} from 'date-fns';
import {CountryCode, parsePhoneNumber} from 'libphonenumber-js/max';
import {
  getBackendUserDocumentType,
  UserDocumentType,
  userDocumentTypeFromBackend,
} from './user-document-types';
import {
  getBackendIssuingEntity,
  IssuingEntity,
  issuingEntityFromBackend,
} from './issuing-entity';
import {
  getBackendTaxIdentificationType,
  taxIdentificationFromBackend,
  TaxIdentificationType,
} from './tax-identification-type';

class UserInternal implements Clonable {
  id: number;

  email: string;

  name: string;

  initials: string;

  surname: string;

  middleName: string;

  suffixName: string;

  birthDate: number;

  birthDateString: string;

  password: string;

  address: string;

  zipCode: string;

  city: string;

  state: string;

  country: string;

  phonePrefix: string;

  phone: string;

  dni: string;

  balance: number;

  picture: string;

  bankAccount: string;

  lotteryBooth: string;

  emailVerified: boolean;

  phoneVerified: boolean;

  creditCardAlias: string;

  creditCardExpirationDate: string;

  autoCredit: number;

  sponsorCode: string;

  sponsorUrl: string;

  @SerializableProperty(UserKyc, SerializableType.OBJECT)
  kyc: UserKyc;

  resident: boolean;

  community: boolean;

  passport: boolean;

  exclusion: UserExclusion;

  accountStateCode: string;

  registrationDate: number;

  groupsHideKeepManaged: boolean;

  documentType: UserDocumentType;

  documentNumber: string;

  documentExpirationDate: number;

  /**
   * Custom entity, Country or country code
   */
  documentIssuingEntity: IssuingEntity | Country | string;

  nif: string;

  nifType: TaxIdentificationType;

  languageCode: string;

  hasRequiredDataFilled(): boolean {
    return (
      (!environment.locale.user.allowDNI || !!this.dni) &&
      (!environment.locale.user.allowDocumentNumber ||
        !!this.documentType ||
        !!this.documentNumber ||
        !!this.documentExpirationDate ||
        !!this.documentIssuingEntity) &&
      !!this.name &&
      !!this.surname &&
      !!this.phone
    );
  }

  hasEnoughBalance(price: number): boolean {
    return this.balance >= price || !!this.autoCredit;
  }

  setAddress(address: any) {
    this.address = address.addressLine;
    this.city = address.city;
    this.zipCode = address.stateZipCode.zipCode;
    this.state = address.stateZipCode.state;
  }

  isEmptyAddress(): boolean {
    return (
      !this.address && !this.city && !this.zipCode && !this.state && !this.country
    );
  }

  clone(): any {
    return deepClone(this);
  }

  parseTimestamp(birthDate: number): number {
    if (environment.serverOnUTC) {
      // parse to UTC, substract the timezone offset
      return addMinutes(
        new Date(birthDate),
        -1 * new Date(birthDate).getTimezoneOffset(),
      ).getTime();
    }
    return birthDate;
  }

  toBackend() {
    let phone = this.phone;

    try {
      if (!!this.phonePrefix && !!this.phone) {
        const phoneToCheck = `${this.phonePrefix}${this.phone}`;
        const defaultCountryCode =
          environment.locale.user.defaultPhoneCode.toUpperCase() as CountryCode;
        const phoneFixed = fixPhoneNumber(phoneToCheck, defaultCountryCode);
        const phoneNumber = parsePhoneNumber(phoneFixed, defaultCountryCode);
        if (phoneNumber.isValid()) {
          phone = `${this.phonePrefix}${this.phone}`;
        } else {
          throw Error('Invalid phone number');
        }
      }
    } catch (e) {
      phone = this.phone;
    }

    const documentExpirationDatePattern =
      environment.locale.user.documentExpirationDatePattern;

    return {
      id: this.id,
      codigo: this.email,
      nombre: this.name,
      apellidos: this.surname,
      iniciales: this.initials,
      fechaNacimiento: this.birthDateString
        ? null
        : this.parseTimestamp(this.birthDate),
      fechaNacimientoString: this.birthDateString,
      newPassword: this.password,
      direccion: this.address,
      cp: this.zipCode,
      poblacion: this.city,
      provincia: this.state,
      pais: this.country,
      telefono: phone,
      cif: this.dni || this.nif,
      saldo: this.balance,
      pictureUrl: this.picture,
      cuentaBancaria: this.bankAccount,
      administracion: this.lotteryBooth,
      emailVerificado: this.emailVerified,
      telefonoVerificado: this.phoneVerified,
      creditCardAlias: this.creditCardAlias,
      creditCardExpiryDate: this.creditCardExpirationDate,
      autoCreditMultiple: this.autoCredit,
      tokenSponsor: this.sponsorCode,
      tokenSponsorUrl: this.sponsorUrl,
      extra: {
        residente: this.resident,
        comunitario: this.community,
        pasaporte: this.passport,
        middleName: this.middleName || undefined,
        suffixName: this.suffixName || undefined,
        userStateCode: this.accountStateCode,
        locale: this.languageCode,
        barcodeUsed: this.kyc?.barcodeUsed,
        groups_hide_keep_managed: this.groupsHideKeepManaged === null || undefined,
        documentType: getBackendUserDocumentType(this.documentType),
        documentNumber: this.documentNumber || undefined,
        documentExpirationDate: this.documentExpirationDate
          ? formatDate(this.documentExpirationDate, documentExpirationDatePattern)
          : undefined,
        documentIssuingEntity: getBackendIssuingEntity(this.documentIssuingEntity),
        cifType: getBackendTaxIdentificationType(this.nifType),
      },
    };
  }
}

export class User extends Serializable(UserInternal) {
  static createFromBackend(obj: any, countries: Array<Country>): User {
    let user = new User();

    user.id = obj.id;
    user.email = obj.codigo;
    user.name = obj.nombre;
    user.surname = obj.apellidos;
    user.middleName = obj.extra?.middleName;
    user.suffixName = obj.extra?.suffixName;
    user.initials = obj.iniciales;
    if (obj.hasOwnProperty('fechaNacimientoString')) {
      user.birthDate = parseISO(obj.fechaNacimientoString).getTime();
    }
    user.birthDateString = obj.fechaNacimientoString;
    user.password = obj.newPassword;
    user.address = obj.direccion;
    user.zipCode = obj.cp;
    user.city = obj.poblacion;
    user.state = obj.provincia;
    user.country = obj.pais;

    if (!!obj.telefono) {
      try {
        const countryCode =
          environment.locale.user.defaultPhoneCode.toUpperCase() as CountryCode;
        const phoneFixed = fixPhoneNumber(obj.telefono, countryCode);

        const phoneNumber = parsePhoneNumber(phoneFixed, countryCode);

        const country = countries.find(
          (currentCountry: Country) =>
            currentCountry.code === phoneNumber.country?.toLowerCase(),
        );
        if (phoneNumber) {
          user.phonePrefix = !!country ? country.prefix : '';
          user.phone = phoneNumber.nationalNumber;
        }
      } catch (e) {
        user.phone = obj.telefono;
        user.phonePrefix = '';
      }
    }

    user.dni = obj.cif;
    user.balance = obj.saldo;
    user.picture = obj.pictureUrl;
    user.lotteryBooth = obj.administracion;
    user.emailVerified = obj.mailVerificado;
    user.phoneVerified = obj.telefonoVerificado;
    user.bankAccount = obj.cuentaBancaria;

    user.creditCardAlias = obj.creditCardAlias;
    user.creditCardExpirationDate = obj.creditCardExpiryDate;
    user.autoCredit = obj.autoCreditMultiple;
    user.sponsorCode = obj.tokenSponsor;
    user.sponsorUrl = obj.tokenSponsorUrl;

    user.resident = obj.extra?.residente;
    user.community = obj.extra?.comunitario;
    user.passport = obj.extra?.pasaporte;
    user.accountStateCode = obj.extra?.userStateCode;
    user.languageCode = obj.extra?.locale;
    user.groupsHideKeepManaged = obj.extra?.groups_hide_keep_managed;
    user.groupsHideKeepManaged = obj.extra?.groups_hide_keep_managed;

    if (environment.locale.user.allowDocumentNumber) {
      user.documentType = userDocumentTypeFromBackend(obj.extra?.documentType);
      user.documentNumber = obj.extra?.documentNumber;
      const pattern = environment.locale.user.documentExpirationDatePattern;
      user.documentExpirationDate = !!obj.extra?.documentExpirationDate
        ? parseDate(obj.extra.documentExpirationDate, pattern, Date.now()).getTime()
        : undefined;
      user.documentIssuingEntity = issuingEntityFromBackend(
        obj.extra?.documentIssuingEntity,
      );
    }
    if (environment.kyc.enableNIF) {
      user.nifType = taxIdentificationFromBackend(obj.extra?.cifType);
      user.nif = obj.cif;
    }

    user.kyc = obj.kyc
      ? UserKyc.createFromBackend(obj.kyc, obj.extra?.barcodeUsed)
      : undefined;

    user.registrationDate = obj.fechaRegistro;
    return user;
  }
}
