import {Inject, Injectable} from '@angular/core';
import {addSeconds, isFuture} from 'date-fns';
import {fromEvent, interval, merge, Observable, of, throwError} from 'rxjs';
import {debounceTime, filter, first, switchMap, tap} from 'rxjs/operators';

import {LocalStorage} from '../storage/local-storage/local-storage';

import {GOOGLE_AUTH_STORE_KEY, GOOGLE_CLIENT_ID} from './config-tokens';
import {GoogleAuthService, GoogleUser} from './google-auth.service';

@Injectable()
export class GoogleAuthRestService extends GoogleAuthService {
  private readonly URL = 'https://accounts.google.com/o/oauth2/v2/auth';

  private token: string | null;

  private idToken: string | null;

  private expires: number | null;

  private scope: string | null;

  private email: string | null;

  constructor(
    @Inject(GOOGLE_AUTH_STORE_KEY) private googleAuthStoreKey: string,
    @Inject(GOOGLE_CLIENT_ID) private googleClient: string,
    @Inject('window') private window: Window,
    private localStorage: LocalStorage,
  ) {
    super();
  }

  login(scopes?: Array<string>): Observable<GoogleUser> {
    const url = new URL(this.URL);
    url.searchParams.set('client_id', this.googleClient);
    url.searchParams.set('redirect_uri', this.window.location.origin + '/fb.html');
    url.searchParams.set('response_type', 'id_token token');
    url.searchParams.set('nonce', Math.random().toString() + Date.now().toString());
    url.searchParams.set('prompt', 'select_account');

    if (scopes) {
      let strScopes = '';
      scopes.forEach(scope => (strScopes += ' ' + scope));
      url.searchParams.append('scope', strScopes);
    }

    const popup = this.window.open(url.toString());

    // If popup is closed without any message emitted we have to throw an error.
    let hasEmitted = false;
    const noPopupEmit = interval(100)
      // We only emit error if popup haven't emited and user closes it.
      .pipe(
        filter(() => !hasEmitted && (!popup || popup.closed)),
        tap(() => {
          throw new Error(
            popup
              ? 'Google auth has been closed without any action'
              : "Google auth window couldn't be oppened",
          );
        }),
      );

    return merge(noPopupEmit, fromEvent(this.window, 'message')).pipe(
      // Filter facebook postMessages, only accept from our domain
      // Ensurce it's a google request
      filter(
        (ev: any) =>
          ev?.origin === this.window.location.origin &&
          typeof ev?.data?.fragment === 'string' &&
          ev.data.fragment.includes('access_token') &&
          ev.data.fragment.includes('expires_in'),
      ),
      first(),
      switchMap((event: any) => {
        hasEmitted = true;
        popup.close();
        let fragment = event.data.fragment;
        if (fragment.startsWith('#')) {
          fragment = fragment.slice(1, fragment.length);
        }

        let params = new URLSearchParams(fragment);
        if (params.has('access_token')) {
          this.token = params.get('access_token');
          this.idToken = params.get('id_token');
          this.expires = addSeconds(Date.now(), +params.get('expires_in')).valueOf();
          this.scope = params.get('scope');
          this.email = this.decodeToken(this.idToken)?.email;
          this.storeToken();
          return of({
            access_token: params.get('access_token'),
            id_token: params.get('id_token'),
            scope: params.get('scope'),
            email: this.email,
          });
        } else {
          return throwError(
            () => new Error(`Error google login fragment: ${fragment}`),
          );
        }
      }),
      // Avoid duplicated requests due to multiple message emissions.
      debounceTime(150),
    );
  }

  logout(): Observable<boolean> {
    this.token = null;
    this.expires = null;
    this.localStorage.removeItem(this.googleAuthStoreKey);
    return of(true);
  }

  getCurrentUser(): Observable<GoogleUser | null> {
    if (!this.token) {
      this.restoreToken();
    }
    return this.token
      ? of({
          access_token: this.token,
          id_token: this.idToken,
          scope: this.scope,
          email: this.email,
        })
      : of(null);
  }

  getLoginStatus(): Observable<boolean> {
    if (!this.token) {
      this.restoreToken();
    }
    return of(isFuture(this.expires));
  }

  grant(scope: string): Observable<GoogleUser> {
    return this.login([scope]);
  }

  private restoreToken() {
    const str = this.localStorage.getItem(this.googleAuthStoreKey);
    if (str) {
      const strChunks = str.split('::');
      if (strChunks.length === 5) {
        this.token = strChunks[0];
        this.idToken = strChunks[1];
        this.expires = +strChunks[2];
        this.scope = strChunks[3];
        this.email = strChunks[4];
      } else {
        this.localStorage.removeItem(this.googleAuthStoreKey);
      }
    }
  }

  private storeToken() {
    this.localStorage.setItem(
      this.googleAuthStoreKey,
      this.token +
        '::' +
        this.idToken +
        '::' +
        this.expires.toString() +
        '::' +
        this.scope.toString() +
        '::' +
        (this.email?.toString() ?? ''),
    );
  }

  private decodeToken(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    );
    return JSON.parse(jsonPayload) as any;
  }
}
