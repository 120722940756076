import {Serializable} from 'common';

// Clase abstracta que contiene las propiedades comunes
export abstract class Booth {
  id: string;
  name: string;
  comercialName: string;
  pictureUrl: string;
  street: string;
  city: string;
  region: string;
  zip: string;
  phones: string[];
  email: string;
  latitude: number;
  longitude: number;
  enabled: boolean;
  distance: number;
}

class BoothLocalInternal extends Booth {
  licencia: string;
  licenciaNombre: string;
  licenciaApellidos: string;
  licenciaDni: string;
  numero: string;
  receptorNumber: string;
  mapImage: string;
}

export class BoothLocal extends Serializable(BoothLocalInternal) {
  static createFromBackend(obj: any) {
    let booth = new BoothLocal();

    booth.id = obj.id;
    booth.name = obj.nombre;
    booth.comercialName = obj.comercial;
    booth.pictureUrl = obj.urlFoto;
    booth.street = obj.direccion;
    booth.city = obj.poblacion;
    booth.region = obj.provincia;
    booth.zip = obj.codigoPostal;
    booth.phones = obj.telefono ? [obj.telefono] : null;
    booth.email = obj.mail;
    booth.latitude = obj.latitud;
    booth.longitude = obj.longitud;
    booth.enabled = obj.enabled;
    booth.licencia = obj.licencia;
    booth.licenciaNombre = obj.licenciaNombre;
    booth.licenciaApellidos = obj.licenciaApellidos;
    booth.licenciaDni = obj.licenciaDni;
    booth.numero = obj.numero;
    booth.receptorNumber = obj.receptorNumber;
    booth.mapImage = obj.mapImage;

    return booth;
  }
}

class BoothRemoteInternal extends Booth {
  retailerId: string;
}

export class BoothRemote extends Serializable(BoothRemoteInternal) {
  static createFromBackend(obj: any) {
    let booth = new BoothRemote();
    booth.id = obj.id;
    booth.retailerId = obj.retailerId;
    booth.name = obj.name;
    booth.comercialName = obj.comercialName;
    booth.pictureUrl = obj.pictureUrl;
    booth.street = obj.street;
    booth.city = obj.city;
    booth.region = obj.region;
    booth.zip = obj.zip;
    booth.phones = obj.phones;
    booth.email = obj.email;
    booth.latitude = obj.latitude;
    booth.longitude = obj.longitude;
    booth.enabled = obj.enabled;
    booth.distance = obj.distance;

    return booth;
  }
}
