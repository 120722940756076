import {Injectable} from '@angular/core';
import {AlertsService, ignoreError, Logger} from 'common';
import {BehaviorSubject, EMPTY, Observable} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';

import {RejectedGeolocationError} from '../geolocation/geolocation-errors';
import {GeolocationService} from '../geolocation/geolocation.service';
import {MeasuringDao} from '../marketing/measuring.dao';

import {LotteryBoothsGeolocationViewService} from './lottery-booths-geolocation-view.service';

@Injectable()
export class LotteryBoothsLocateViewService {
  loadingLocation = new BehaviorSubject(false);

  get isGeolocationSupported(): boolean {
    // also disable if already granted
    return this.geolocationService.isGeolocationSupported();
  }

  constructor(
    protected alertsService: AlertsService,
    public boothGeolocationViewService: LotteryBoothsGeolocationViewService,
    protected logger: Logger,
    protected geolocationService: GeolocationService,
    protected measuringDao: MeasuringDao,
  ) {}

  locate(): Observable<GeolocationPosition> {
    if (this.loadingLocation.getValue()) {
      return EMPTY;
    }

    this.measuringDao.sendTagManagerEvent('admin_geolocation');

    return this.enableGeolocation().pipe(
      catchError(err => {
        this.logger.error(err?.message, err?.stack, err);
        this.alertsService.notifyError(
          err.message || {key: 'geolocation.geolocationError'},
        );
        return EMPTY;
      }),
    );
  }

  disableGeolocation() {
    this.boothGeolocationViewService.disableGeolocation();
  }

  private enableGeolocation(): Observable<GeolocationPosition> {
    this.loadingLocation.next(true);
    return this.boothGeolocationViewService.enableGeolocation(true).pipe(
      ignoreError<GeolocationPosition, GeolocationPosition>(
        RejectedGeolocationError,
      ),
      finalize(() => this.loadingLocation.next(false)),
    );
  }
}
