import {es} from 'date-fns/locale';

import {ROUTES_ES} from '../routes/es';
import {TIMEZONE_NAME} from '../timezone/mexico-city';
import {SearchLotteryType} from '../../app/games/game-metadata/data/search-lottery-type';

export const ENVIRONMENT_MX = {
  id: 'mx',
  fulldomain: 'web.tulotero.mx',
  locale: {
    domain: '.mx',
    locale: 'es-MX',
    dateLocale: es,
    dateFormats: {
      format: 'dd/MM/YYYY',
      shortDayMonthYear: 'dd/MM/yy',
      dayMonthNameYear: 'd MMM, y',
      dayMonth: 'd MMM.',
      date: 'P',
      dateShort: 'dd MMM. - HH:mm',
      dayNameDate: 'eeee, P',
      dayNameMonthDate: 'eee, d MMM',
      dayMonthTime: 'd MMM., HH:mm',
      shortMonth: 'MMM',
      time: 'HH:mm',
      shortDateAndTime: 'dd/MM/yyyy HH:mm',
      dayName: 'eee',
    },
    zipCodeFormat: '00000',
    currencyCode: 'MXN',
    timezone: TIMEZONE_NAME,
    partnersLogo: 'assets/img/logos/logos-confianza-mx5.png',
    sponsorLogo: 'assets/img/menu/embajador-USD.svg',
    user: {
      defaultCountry: 'mx',
      defaultPhoneCode: 'mx',
      allowDNI: false,
      allowApostrophes: true,
    },
    bankAccountType: 'CLABE',
    menu: {
      downloadAndroidApp: true,
      downloadIosApp: true,
    },
    routes: ROUTES_ES,
  },
  app: {
    ios: {
      id: '948041023',
      link: 'https://itunes.apple.com/MX/app/id948041023?pid=pwa_menu',
    },
    android: {
      link: 'https://tulotero.mx/descarga-android',
    },
  },
  fingerprint: {
    region: 'us',
    endpoint: 'https://fp.web.tulotero.mx',
  },
  money: {
    pci: true,
    amountLoad: {
      showDecimals: true,
      allowInputManual: true,
    },
    // TODO move to paymentMethods when credit card is migrated
    defaultPageDesktop: 'CARD',
    transfer: {
      enableSpei: true,
    },
  },
  selfControl: {
    removeLimits: false,
  },
  menu: {
    // TODO revertir cuando acabe el inverno
    background: 'assets/img/mobile/menu/background-christmas.jpg',
    backgroundChristmas: 'assets/img/mobile/menu/background-christmas.jpg',
  },
  profile: {
    // TODO revertir cuando acabe el inverno
    background: 'assets/img/mobile/perfil/background-christmas.jpg',
    backgroundChristmas: 'assets/img/mobile/perfil/background-christmas.jpg',
    hiddenRequiredData: false,
  },
  init: {
    screenLogo: 'assets/img/mobile/screen-android-es-mx.png',
    providerLogos: [
      'assets/img/logos/lotenal-blanco.svg',
      'assets/img/logos/logo-sorteos-tec.svg',
    ],
  },
  balance: {
    showKycBanner: true,
  },
  games: {
    play: {
      savedPlayTimeout: 900,
    },
    results: {showCheckBanner: false},
    lottery: {
      autheticatedImageTickets: true,
      allowCustomSearch: true,
      hideFilterButtons: true,
      mobileWheelSmallFit: true,
      ticketsSearchSizeClass: 'medium-large',
      searchLotteryType: SearchLotteryType.LOTENAL,
    },
  },
  groups: {
    enable: true,
    showInfoPrizes: true,
    userBoxPrice: false,
    shareUrl: 'https://tulotero.mx/g/',
  },
  preferences: {
    notificationCategory: 'notificaciones',
  },
  registration: {smsTimeout: {desktop: 80, mobile: 60}},
  social: {
    sponsorImg: 'assets/img/social/image-sponsor-mx.jpg',
  },
  features: {
    reserveMode: true,
    groups: {
      filterGames: true,
    },
    money: {
      uploadVoucher: true,
    },
    subscribe: {
      enable: true,
      maxDaysSelectorBreakpoint: 'lg',
    },
  },
  maintenance: {redirectUrl: 'maintenance-es.html'},
};
