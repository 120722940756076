import {NgModule} from '@angular/core';
import {SessionStorageService} from './session-storage.service';
import {MemoryStorageService} from '../local-storage/memory-storage.service';
import {SessionStorage} from './session-storage';

export function sessionStorageFactory():
  | SessionStorageService
  | MemoryStorageService {
  return SessionStorage.isAvailable()
    ? new SessionStorageService()
    : new MemoryStorageService();
}
@NgModule({
  providers: [{provide: SessionStorage, useFactory: sessionStorageFactory}],
})
export class SessionStorageModule {}
