import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {ENVIRONMENT} from '../../environment/environment-token';
import {DistanceUnit} from '../../util/distance-unit';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-distance-display',
  templateUrl: './distance-display.component.html',
  styleUrls: ['./distance-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DistanceDisplayComponent implements OnChanges {
  @Input()
  distance: number;

  @Input()
  unit: DistanceUnit = DistanceUnit.METERS;

  colorClass: string;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.renderer.removeClass(this.element.nativeElement, this.colorClass);

    if (this.distance > this.environment.features.booths.distanceAlert.far) {
      this.colorClass = 'red';
    } else if (this.distance > this.environment.features.booths.distanceAlert.near) {
      this.colorClass = 'orange';
    } else {
      this.colorClass = 'green';
    }

    this.renderer.addClass(this.element.nativeElement, this.colorClass);
  }
}
