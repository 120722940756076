import {AsyncPipe} from '@angular/common';
import {
  ChangeDetectorRef,
  Inject,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import {differenceInCalendarDays, isSameYear} from 'date-fns';
import {Observable} from 'rxjs';

import {TranslationsService} from '../../i18n/translations.service';
import {formatDate} from '../date-fns-wrapper';
import {ENVIRONMENT} from '../../environment/environment-token';

@Pipe({name: 'timestampToDateFromNow', pure: false})
export class TimestampToDateFromNowPipe implements PipeTransform, OnDestroy {
  private asyncPipe: AsyncPipe;

  constructor(
    cdr: ChangeDetectorRef,
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
    private translationsService: TranslationsService,
  ) {
    this.asyncPipe = new AsyncPipe(cdr);
  }

  transform(value: number, calcDiff = true): any {
    if (!value) {
      return '';
    }

    const date = new Date(value);

    if (calcDiff && Math.abs(differenceInCalendarDays(Date.now(), date)) <= 7) {
      let daysDiff = differenceInCalendarDays(date, Date.now());

      let ofservableToReturn: Observable<string>;
      // If you are wondering why switch is not used here, see that:
      // https://github.com/Microsoft/TypeScript/issues/12612
      if (daysDiff === 0) {
        ofservableToReturn = this.translationsService.getCompiledTranslation(
          'games.time.atTime',
          {time: formatDate(date, this.environment.locale.dateFormats.time)},
        );
      } else if (daysDiff === -1) {
        ofservableToReturn = this.translationsService.getTranslation(
          'games.time.daysAgo.one',
        );
      } else if (daysDiff === 1) {
        ofservableToReturn =
          this.translationsService.getTranslation('games.time.tomorrow');
      } else if (daysDiff < 0) {
        const days = Math.abs(daysDiff);
        ofservableToReturn = this.translationsService.getCompiledTranslation(
          days === 1 ? 'games.time.daysAgo.one' : 'games.time.daysAgo.other',
          {days: days},
        );
      } else {
        ofservableToReturn = this.translationsService.getCompiledTranslation(
          daysDiff === 1 ? 'games.time.onDays.one' : 'games.time.onDays.other',
          {days: daysDiff},
        );
      }

      return this.asyncPipe.transform(ofservableToReturn);
    } else if (!isSameYear(Date.now(), date)) {
      return formatDate(date, this.environment.locale.dateFormats.dayMonthNameYear);
    } else {
      return formatDate(date, this.environment.locale.dateFormats.dayNameMonthDate);
    }
  }

  ngOnDestroy() {
    this.asyncPipe.ngOnDestroy();
  }
}
