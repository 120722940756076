import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import {StepMetadata} from './step-metadata';

@Component({
  selector: 'tl-vertical-stepper',
  templateUrl: './vertical-stepper.component.html',
  styleUrls: ['./vertical-stepper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalStepperComponent {
  @HostBinding('class.tl-vertical-stepper') readonly hostClass = true;

  @Input()
  steps: Array<StepMetadata> = [];

  trackByFn(index: number, step: StepMetadata): number {
    return index;
  }
}
