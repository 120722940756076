import {Injectable} from '@angular/core';
import {CountryService, MessageHookDataService} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {BannerDataService} from '../../banner/data/banner-data.service';
import {LotteryBoothService} from '../../booth/data/lottery-booth.service';
import {GameFiltersService} from '../../games/game-filter/data/game/game-filters.service';
import {ResultFiltersService} from '../../games/game-filter/data/result/result-filters.service';
import {TicketFiltersService} from '../../games/game-filter/data/ticket/ticket-filters.service';
import {GameMetadataService} from '../../games/game-metadata/data/game-metadata.service';
import {ResultsService} from '../../games/results/data/results.service';
import {SentencesService} from '../../games/sentences/sentences.service';
import {UpcomingGamesService} from '../../games/upcoming/data/upcoming-games.service';
import {BackendDao} from '../backend.dao';
import {EndpointService} from '../endpoint/endpoint.service';

import {PublicInfo} from './public.info';
import {StatesService} from '../../states/data/states.service';
import {BoothsUriMapperService} from '../../booth/data/booths-uri-mapper';
import {PromosCardService} from '../../games/upcoming/data/promo-cards.service';

@Injectable({providedIn: 'root'})
export class PublicInfoService {
  constructor(
    private backendDao: BackendDao,
    private endpointService: EndpointService,
    private gameFiltersService: GameFiltersService,
    private gameMetadataService: GameMetadataService,
    private lotteryBoothService: LotteryBoothService,
    private bannerService: BannerDataService,
    private messageHookDataService: MessageHookDataService,
    private resultFiltersService: ResultFiltersService,
    private resultsService: ResultsService,
    private sentencesService: SentencesService,
    private statesService: StatesService,
    private ticketFiltersService: TicketFiltersService,
    private upcomingGamesService: UpcomingGamesService,
    private promoCardsService: PromosCardService,
    private countryService: CountryService,
    private boothsUriMapperService: BoothsUriMapperService,
  ) {}

  loadAllInfo(): Observable<PublicInfo> {
    return this.backendDao.allInfoPublic().pipe(
      map((publicInfo: PublicInfo) => {
        this.gameMetadataService.setMetadata(publicInfo.gameMetadata);
        this.upcomingGamesService.setData(publicInfo.upcomingGames);
        this.sentencesService.setData(publicInfo.sentences);
        this.resultsService.setData(publicInfo.results);
        if (publicInfo.endpointInfo.allowBooths) {
          this.lotteryBoothService.setData(publicInfo.lotteryBooths);
        }
        this.endpointService.setData(publicInfo.endpointInfo);
        this.statesService.setData(publicInfo.states);

        if (!!publicInfo.promoCards) {
          this.promoCardsService.setData(publicInfo.promoCards);
        }

        this.resultFiltersService.setFiltersFromBackend(
          publicInfo.endpointInfo.resultFilters,
          publicInfo.endpointInfo.fixedResultFilters,
        );
        this.ticketFiltersService.setFiltersFromBackend(
          publicInfo.endpointInfo.ticketFilters,
          publicInfo.endpointInfo.fixedTicketFilters,
        );
        if (publicInfo.endpointInfo.retailersEndpoint) {
          this.boothsUriMapperService.setData(
            publicInfo.endpointInfo.retailersEndpoint.reduce(
              (acc, next) => acc.set(next.region, next.url),
              new Map(),
            ),
          );
        }

        if (publicInfo.endpointInfo.gameFilters) {
          this.gameFiltersService.setFiltersFromBackend(
            publicInfo.endpointInfo.gameFilters,
            publicInfo.endpointInfo.fixedGameFilters,
          );
        }

        this.bannerService.setData(
          [
            publicInfo.messageBanner,
            ...(publicInfo?.messagesBannerByState ?? []),
          ].filter(x => !!x),
        );
        this.messageHookDataService.setData(publicInfo.endpointInfo.messageHooks);
        this.countryService.setData(publicInfo.countries);
        return publicInfo;
      }),
    );
  }
}
