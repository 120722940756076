import {Injectable} from '@angular/core';
import {distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs';

import {User} from '../user/data/user';
import {UserService} from '../user/data/user.service';
import {LocaleService, TranslationsService} from 'common';

@Injectable({
  providedIn: 'root',
})
export class UserLocaleListenerService {
  constructor(
    private userService: UserService,
    private localeService: LocaleService,
    private translationsService: TranslationsService,
  ) {}

  /**
   * Initialize listener
   */
  init(): void {
    this.userService
      .getData()
      .pipe(
        filter((user: User) => !!user),
        map((user: User) => user?.languageCode),
        filter((localeCode: string) => !!localeCode),
        distinctUntilChanged(),
        tap((localeCode: string) => this.localeService.setLocale(localeCode)),
        switchMap(() => this.translationsService.load()),
      )
      .subscribe();
  }
}
