<div
  class="booth-item-description"
  [class.booth-item-description--list-mode]="listMode">
  <div class="img-wrapper" *ngIf="!listMode">
    <img
      src="{{ lotteryBooth.picture }}"
      alt="Foto Admin."
      class="img-fluid"
      tlImgDefault="assets/img/administraciones/sin-imagen.jpg" />
  </div>
  <div class="booth-item-content">
    <div class="booth-name" [class.with-menu]="showMenu">
      <span>{{ lotteryBooth.name }}</span>
      <span
        *ngIf="lotteryBooth['distance'] && showDistance"
        class="booth-distance booth-distance__top">
        <tl-distance-display
          [distance]="lotteryBooth['distance']"
          [unit]="distanceUnit"></tl-distance-display>
      </span>
      <div class="menu-toogle" *ngIf="showMenu" (click)="onToggleMenu()">
        <i class="icon-ellipsis"></i>
      </div>
    </div>
    <ng-container *ngIf="listMode; else addressExtended">
      <span class="booth-address--list">{{ boothDescription }}</span>
    </ng-container>
    <ng-template #addressExtended>
      <span class="booth-address">{{ lotteryBooth.address }}</span>
      <span class="booth-location"
        >{{ lotteryBooth.city }} ({{ lotteryBooth.state }})</span
      >
      <span class="booth-contact-info" *ngIf="showContactInfo">
        <span *ngIf="lotteryBooth.phones" class="anchor-span">
          <ng-container *ngFor="let phone of lotteryBooth.phones; let last = last">
            <a [href]="phone | phoneLink">
              {{ phone | phoneFormat }}
            </a>
            <span *ngIf="!last"> / </span>
          </ng-container>
        </span>
        <span *ngIf="lotteryBooth.hasPhones && lotteryBooth.email">
          &nbsp;|&nbsp;
        </span>
        <span *ngIf="lotteryBooth.email" class="anchor-span">
          <a [href]="lotteryBooth.email | emailLink">{{ lotteryBooth.email }}</a>
        </span>
      </span>
    </ng-template>
    <span
      *ngIf="lotteryBooth['distance'] && showDistance"
      class="booth-distance booth-distance__bottom">
      <tl-distance-display
        [distance]="lotteryBooth['distance']"
        [unit]="distanceUnit"></tl-distance-display>
    </span>
  </div>
  <i *ngIf="enableToggle" class="toggle-arrow icon-right-slim-icon"></i>
  <ng-content></ng-content>
</div>
<div class="booth-info" *ngIf="toggleState">
  <div>
    <span class="icon icon-location-icon"></span>
    <span>{{ fullAddress }}</span>
    &nbsp;
    <a
      [href]="lotteryBooth.mapsLink"
      (click)="$event.stopPropagation()"
      target="_blank">
      ({{ 'administrationOffice.goMap' | i18n }})
    </a>
  </div>
  <div class="anchor-span">
    <span class="icon icon-phone-icon"></span>
    <ng-container *ngFor="let phone of lotteryBooth.phones; let last = last">
      <a [href]="phone | phoneLink">
        {{ phone | phoneFormat }}
      </a>
      <span *ngIf="!last"> / </span>
    </ng-container>
  </div>
  <div *ngIf="lotteryBooth.email" class="anchor-span">
    <span class="icon icon-mail-alt-icon"></span>
    <a [href]="lotteryBooth.email | emailLink">{{ lotteryBooth.email }}</a>
  </div>
</div>
