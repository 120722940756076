<div class="tl-page__title">
  <ng-container *tlIfMobile>
    <ng-content select="[tl-page-header-mobile]"></ng-content>
  </ng-container>

  <div
    *tlIfDesktop
    class="tl-page__title--desktop"
    [ngClass]="{'tl-page__title--desktop--wrapped': showElipsisOnOverflow}">
    <ng-container *ngIf="enableBack">
      <div class="tl-page__title-back-button" (click)="backOnClick()">
        <i class="icon-left-slim-icon"></i>
      </div>
      <span class="tl-page__title-separator">/</span>
    </ng-container>
    <h1>
      <ng-container *ngIf="title | isTranslatableText; else notTranslatable">
        {{ title.key | i18n }}
      </ng-container>
      <ng-template #notTranslatable>{{ title }}</ng-template>
    </h1>
    <ng-content select="[tl-page-title-right]"></ng-content>
    <tl-info-basic
      class="tl-page__title-info"
      *ngIf="showInfo"
      (openInfo)="openInfo()"></tl-info-basic>
  </div>
</div>

<div class="tl-page__content">
  <tl-scrollable class="tl-scrollable--fit">
    <ng-content select="[tl-page-content]"></ng-content>
  </tl-scrollable>
</div>
