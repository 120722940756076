<div
  class="lottery-booths-list-header mobile-section-shadow"
  [class.lottery-booths-list-header--list-mode]="listMode">
  <div class="lottery-booths-search" [class.section-shadow]="mode === 'select'">
    <div class="input-wrapper">
      <i class="icon-search-icon"> </i>
      <input
        #search
        class="form-control form-control-sm"
        type="text"
        [placeholder]="'administrationOffice.adminSearchPlaceholder' | i18n" />

      <button
        type="button"
        class="geoloc-btn"
        [class.selected]="watchingGeoLocation"
        (click)="toogleLocate()"
        id="booths-geolocation"
        [tlTooltip]="'administrationOffice.adminSelectMapOrderLocation' | i18n"
        placement="bottom-center"
        [tooltipClass]="'tl-tooltip tooltip tl-tooltip-booth-geolocation'">
        <i
          class="geoloc-btn--icon"
          [ngClass]="{
            'icon-cursor-right-icon': (loadingLocation | async) === false,
            'icon-spin6-icon tl-icon-spin': loadingLocation | async,
            off: (boothGeolocationViewService.geolocationEnabled | async) === false,
            disabled: !lotteryBoothsLocateViewService.isGeolocationSupported
          }">
        </i
        >{{ 'administrationOffice.location.button' | i18n }}
      </button>
    </div>
  </div>
  <ng-container *ngIf="mode === 'favorite'">
    <div
      *ngIf="!currentBoothId"
      class="no-fav-hint"
      (click)="toggleHelp(); $event.stopPropagation()">
      {{ 'administrationOffice.adminRandomPicker' | i18n }}
      <tl-info-basic
        icon="info"
        class="lottery-booths-list__info-basic"
        *ngIf="mode === 'favorite'"></tl-info-basic>
    </div>
    <div
      *ngIf="helpOpenned"
      [@SlideFromBottom]="true"
      class="admin-help"
      (click)="toggleHelp()">
      <b>{{ 'administrationOffice.randomHelp.title' | i18n }}</b
      >:<br /><br />
      <tl-translatable-text
        [text]="{
          key: 'administrationOffice.randomHelp.message'
        }"></tl-translatable-text>
    </div>
  </ng-container>
</div>

<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="'favorite'">
    <tl-virtual-scroll class="scrollable" *ngIf="booths?.length > 0">
      <cdk-virtual-scroll-viewport
        [itemSize]="itemSize"
        [minBufferPx]="minBufferPx"
        [maxBufferPx]="maxBufferPx"
        orientation="'vertical">
        <tl-booth-favourite-item
          *cdkVirtualFor="let booth of booths; trackBy: trackByBoothId"
          [isFavorite]="booth.id === currentBoothId"
          [isLoading]="(this.boothSelectedService.loadingBooth | async) === booth"
          [lotteryBooth]="booth"
          (clickFav)="onBoothSelected(booth)">
        </tl-booth-favourite-item>
      </cdk-virtual-scroll-viewport>
    </tl-virtual-scroll>
  </ng-container>
  <ng-container *ngSwitchCase="'select'">
    <tl-virtual-scroll class="scrollable" *ngIf="booths?.length > 0">
      <cdk-virtual-scroll-viewport
        [itemSize]="itemSize"
        orientation="'vertical"
        [minBufferPx]="minBufferPx"
        [maxBufferPx]="maxBufferPx">
        <tl-lottery-booth-item
          *cdkVirtualFor="let lotteryBooth of booths; trackBy: trackByBoothId"
          [lotteryBooth]="lotteryBooth"
          [distanceUnit]="distanceUnit"
          [showContactInfo]="showContactInfo"
          [listMode]="listMode">
          <div class="booth-item-radio" *ngIf="!readonly">
            <label
              class="round-checkbox"
              (click)="onBoothSelection($event, lotteryBooth)">
              <input
                type="checkbox"
                [disabled]="lotteryBooth.id === currentBoothId"
                [checked]="lotteryBooth.id === currentBoothId" />
              <span></span>
            </label>
          </div>
        </tl-lottery-booth-item>
      </cdk-virtual-scroll-viewport>
    </tl-virtual-scroll>
  </ng-container>
</ng-container>

<div *ngIf="booths?.length === 0" class="empty-list">
  <tl-translatable-text [text]="emptyLabel"></tl-translatable-text>
  <img
    src="assets/img/administraciones/administracion.svg"
    [alt]="'administrationOffice.adminSearchEmptyImageAlt' | i18n" />
</div>

<div *ngIf="loading" class="lottery-booths-list__loader typing-loader"></div>
