import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LOCAL_URIS} from './local-uris';

@Injectable()
export class LocalUrisInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let localUri = request.url;
    Object.keys(LOCAL_URIS).forEach(key => {
      localUri = localUri.replace(key, LOCAL_URIS[key]);
    });
    const localRequest = request.clone({url: localUri});
    return next.handle(localRequest);
  }
}
