export const ROUTES_ES = {
  companies: 'empresas',
  editCompanies: 'empresas/editar',
  editClubSegment: 'editar',
  shareClubSegment: 'compartir',
  companyClubParam: 'clubId',

  play: {
    base: 'jugar',
    game: 'jugar/:id',
    raffle: 'jugar/:id/:raffleId',
    lotteryNumberParam: 'numero',
    manual: 'manual',
    rules: 'reglas',
    share: 'compartir',
    sharePercents: 'compartir/porcentajes',
    shareOptions: 'compartir/opciones',
    address: 'direccion',
    shipment: 'envio',
    collection: 'recogida',
    booth: 'administracion',
    manualMode: {
      booths: 'administraciones',
      map: 'administracion/:boothId',
      location: 'ubicacion',
      rules: 'reglas',
    },
  },

  clubs: {
    base: 'jugar/peñas',
    clubSegment: 'peñas',
    newClub: 'jugar/peñas/:gameId/:raffleId/:tabId/nueva',
    newSegment: 'nueva',
    editClub: 'jugar/peñas/:gameId/:raffleId/:tabId/editar/:clubId',
    editSegment: 'editar',
    clubSms: 'jugar/peñas/:gameId/:raffleId/:tabId/compartir/sms',
    shareSegment: 'compartir',
    shareQueryParam: 'compartir',
    clubParam: 'peña',
    helpSegment: 'ayuda',

    list: ':gameId/:raffleId/:tabId',
    tabs: {
      all: 'todas',
      fixed: 'fijas',
      solidary: 'solidary',
      rockets: 'rockets',
      companies: 'empresas',
      private: 'privadas',
      share: 'compartir',
      shareSms: 'sms',
      contacts: 'google-contacts',
    },

    update: {
      image: 'imagen',
      book: 'reserva',
      description: 'descripcion',
    },
    new: {
      image: 'imagen',
      help: 'ayuda',
      tickets: 'decimos',
      booths: 'administraciones',
      options: 'opciones',
      map: 'ubicacion',
      completed: 'completado',
    },
    register: {
      base: ':code',
      help: 'ayuda',
      register: 'registro',
      login: 'login',
      terms: 'terminos-y-condiciones',
      data: 'datos',
      phone: 'telefono',
      sms: 'sms',
      load: 'cargar',
      tpv: 'cargar/tpv',
      remember: 'login/recordar',
    },
  },

  tickets: {
    ticket: ':id',
    subscription: 'abono',
    subscribe: 'abonarse',
    unsubscribe: 'desabonarse',
    archive: 'archivar',
    share: 'compartir',
    shareGroupSegment: 'grupos',
    shareContactsSegment: 'contactos',
    sharePercents: 'compartir/porcentajes',
    shareOptions: 'compartir/opciones',
    buyMore: 'comprar',
    edit: 'editar',
    info: 'info',
    repeat: 'repetir',
    proof: 'constancia',
    proofData: 'datos',
    download: 'descarga',
    returnPrize: 'devolver-premio',
    ship: 'enviar',
    result: 'escrutinio',
    qr: 'qr',
    image: 'imagen',
    collection: 'recogida',
    delete: 'borrar',
    modalParam: 'modal',
  },

  booths: 'administraciones',
  boothParam: 'admin',
  retailers: 'distribuidores',
  check: 'comprobar',
  checkParams: {
    type: 'tipo',
    voucher: 'papeleta',
    code: 'codigo',
  },
  contactUs: 'contacto',
  shipment: 'envios',
  notifications: 'notificaciones',
  faq: 'preguntas-frecuentes',
  promos: 'promociones',
  promoParam: 'codigo',
  redirect: 'redireccion/:target',
  redirectSegment: 'redireccion',
  legal: 'terminos-y-condiciones',
  plus18: 'menores-de-18',
  responsibleGame: 'juego-responsable',
  lotteryScams: 'estafas-loteria',
  termsLimitLoad: 'terminos-limite-carga',
  selfExclusionInfo: 'autoexclusion-info',
  register: 'usuario/registro',
  registerSegment: 'registro',
  prizes: 'premios',
  prizeParam: 'index',
  prizeView: ':index',
  sponsor: 'embajador',
  language: 'idioma',

  money: {
    balance: 'saldo',
    load: 'cargar',
    priceParam: 'precio',
    screenParam: 'screen',
    transfer: 'transferencia',
    transferPreview: 'vista-previa',
    depositPreview: 'vista-previa',
    transferPreviewMobile: 'transferencia/vista-previa',
    transferSpei: 'spei',
    paymentMethodParam: 'paymentMethod',
    selectMethod: 'añadir',
    addWorldPay: 'añadir/tarjeta',
    addTrustly: 'añadir/trustly',
    fillUserTrustly: 'añadir/trustly/datos-usuario',
    addFiserv: 'añadir/fiserv',
    annualSummary: 'resumen-anual',
  },

  selfExclusion: {
    path: 'autoexclusion',
    selfExclusion: 'autoexclusion',
    confirm: 'confirmar',
    limits: {
      path: 'limites',
      max: {
        path: 'maximos',
        remove: {
          path: 'eliminar',
          info: 'informacion',
          questionnaire: 'cuestionario',
        },
      },
    },
  },

  state: {
    path: 'estado',
    select: 'elegir',
  },

  groups: {
    base: 'grupo',
    balance: {
      base: 'saldo',
      load: 'cargar',
      loadPending: 'cargar-pendiente',
      request: 'solicitar',
      loadRequire: 'cargar-y-solicitar',
      distribute: 'repartir',
      moneyPrizeBox: 'hucha-premios',
      moneyPrizeBoxHistory: 'historial',
      distributeMoneyPrizeBox: 'repartir-hucha',
      transferMoneyPrizeBox: 'tranferir-hucha',
    },
    play: 'jugar',
    games: 'juegos',
    tickets: 'boletos',
    ticket: 'boleto',
    chat: 'chat',
    join: 'unirse',
    edit: 'editar',
    members: {
      base: 'miembros',
      add: 'añadir',
      sms: 'sms',
      contacts: 'google-contacts',
    },
    options: 'opciones',
    new: {
      base: 'nuevo',
      image: 'imagen',
      members: 'miembros',
      balance: 'saldo',
      load: 'cargar-saldo',
      booths: 'administraciones',
    },
  },

  notFound: '404',

  desktop: {
    ticket: 'boleto',
    sponsor: 'embajador',
    news: 'noticias',
    newsArticle: 'noticias/:newsId',
    results: 'resultados/:id',
    resultSegment: 'resultados',
    balance: 'saldo',
    profile: 'usuario/perfil',
    kyc: 'kyc',

    money: {
      card: 'cargar/tarjeta-credito',
      deposit: 'cargar/deposito',
      activities: 'movimientos',
      annualSummary: 'resumen-anual',
      load: 'cargar',
      unload: 'retirar',
      tpv: 'tpv',
      bizum: {
        path: 'bizum',
        config: 'config',
        tpv: 'load',
      },
      mbWay: {
        path: 'mb-way',
        config: 'configuracion',
        tpv: 'cargar',
      },
      requestGreatPrize: 'solicitar-gran-premio',
      requestGreatPrizeBigPrizeParam: 'premio-grande',
      requestGreatPrizeMediumPrizeByIntegratorParam: 'premio-mediano',
    },

    shipments: {
      shipComplete: 'envio-completado',
      collectionComplete: 'recogida-completado',
    },

    user: {
      data: 'datos',
      phone: 'telefono',
      sms: 'sms',
      smsCodeParam: 'code',
      kyc: 'identidad',
      login: 'login',
    },
  },
  mobile: {
    push: 'notificaciones-push',
    blockedNotifications: 'notificaciones-bloqueadas',
    installation: 'instalacion',
    load: 'saldo/cargar',
    unload: 'saldo/retirar',
    requestGreatPrize: 'saldo/retirar-premio',
    requestGreatPrizeTicket: 'boleto',
    requestGreatPrizeBigPrizeParam: 'premio-grande',
    requestGreatPrizeMediumPrizeByIntegratorParam: 'premio-mediano',
    annualSummary: 'saldo/resumen-anual',
    clubHelp: 'jugar/peñas/ayuda',
    googleContactsClub:
      'jugar/peñas/:gameId/:raffleId/:tabId/compartir/google-contacts',
    clubsList: 'jugar/peñas/:gameId/:raffleId',
    newsArticle: 'usuario/noticias',
    init: 'usuario/init',
    kyc: 'usuario/identidad',
    sponsorFriends: 'usuario/embajador/amigos',
    sponsorHelp: 'usuario/embajador/ayuda',
    sponsorSms: 'usuario/embajador/sms',
    googleContacts: 'usuario/google-contacts',
    login: 'usuario/login',
    rememberPwd: 'usuario/login/recordar',
    rememberSegment: 'recordar',
    userPhone: 'usuario/telefono',
    userPhoneSegment: 'telefono',
    userData: 'usuario/datos',
    userImage: 'usuario/perfil/imagen',
    userSms: 'usuario/sms',
    smsSegment: 'sms',
    user: 'usuario',
    proof: 'boletos/boleto/:id/constancia',
    shareTicket: 'boletos/boleto/:id/compartir',
    sharePercents: 'boletos/boleto/:id/compartir/porcentajes',
    shareOptions: 'boletos/boleto/:id/compartir/opciones',
    ticketsByRaffle: 'boletos/sorteo',

    main: {
      money: 'saldo',
      tickets: 'boletos',
      ticket: 'boleto',
      results: 'resultados',
      result: 'resultado',
      games: 'juegos',
      groups: 'grupos',
      apk: 'apk',
      menu: 'menu',
    },

    boothSelector: {
      options: 'opciones',
    },
    favouriteBooth: {
      options: 'opciones',
    },

    scanner: {
      help: 'ayuda',
      game: ':id',
      raffle: ':id/:raffleId',
      manual: 'manual',
    },

    results: {
      result: ':id',
      score: 'resultados',
    },

    money: {
      tpv: 'tpv',
      bizum: 'bizum',
      mbWay: 'mb-way',
    },

    shipments: {
      address: 'direccion',
    },

    tuloteroSlides: {
      profile: 'perfil',
      sponsor: 'embajador',
      news: 'noticias',
    },

    sponsorContacts: {
      ranking: 'ranking',
      achieved: 'conseguidos',
      contacts: 'agenda',
      friends: 'amigos-tulotero',
      help: 'help',
    },
  },
};
