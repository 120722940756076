import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {b64_to_utf8, CookiesService, ResponsiveService} from 'common';
import {Observable, ReplaySubject, throwError} from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  first,
  map,
  mapTo,
  switchMap,
} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {TuloteroDeviceService} from '../../common/device/tulotero-device.service';
import {FacebookDao} from '../../common/facebook/data/facebook.dao';
import {GoogleDao} from '../../common/google/google.dao';
import {AppleDao} from '../../common/apple/apple.dao';
import {TaskContext} from '../../common/scheduler/task-context';
import {TaskManager} from '../../common/scheduler/task-manager';
import {InstallationService} from '../../marketing/installation.service';
import {MeasuringDao} from '../../marketing/measuring.dao';
import {LoginResponse} from '../data/login-response';
import {UserDao} from '../data/user.dao';
import {UserService} from '../data/user.service';
import {UserCredentials} from '../data/usercredentials';

import {BasicAuth} from './basic.auth';
import {SessionInterceptorService} from './session-interceptor.service';
import {FailedAuthData, FailedAuthErrorType} from './failed-auth.types';
import {CredentialStorageService} from './credential-storage.service';

@Injectable({providedIn: 'root'})
export class SessionService {
  token: string;

  persist = false;

  userLoginEvent: EventEmitter<any> = new EventEmitter<any>();

  userRestoreEvent: EventEmitter<any> = new EventEmitter<any>();

  userLogoutEvent: EventEmitter<any> = new EventEmitter<any>();

  private loggedIn: ReplaySubject<boolean> = new ReplaySubject(1);

  private failedAuth: FailedAuthData;

  private failedAuthKey = 'tl_failed_login';

  private failedAuthLimit = 5;

  private failedAuthTimeout = 60000;

  private readonly DEFAULT_AUTH_PERSIST =
    typeof environment.auth.persist === 'boolean' ? environment.auth.persist : true;

  constructor(
    private cookieService: CookiesService,
    private deviceService: TuloteroDeviceService,
    private facebookDao: FacebookDao,
    private installationService: InstallationService,
    private googleDao: GoogleDao,
    private appleDao: AppleDao,
    private credentialStorageService: CredentialStorageService,
    private measuringDao: MeasuringDao,
    private responsiveService: ResponsiveService,
    private router: Router,
    private sessionInterceptors: SessionInterceptorService,
    private taskManager: TaskManager,
    private userDao: UserDao,
    private userService: UserService,
  ) {
    this.sessionInterceptors.logoutPatch = this.logoutNoUnregister.bind(this);
    this.loadFailedAuthData();
  }

  login(
    credentials: UserCredentials,
    checkLocked: boolean = true,
  ): Observable<void> {
    return this.serviceLogin(
      id => {
        this.persist = credentials.remember;
        credentials.installationId = id;
        return this.userDao.login(credentials);
      },
      null,
      credentials,
      checkLocked,
    );
  }

  loginFacebook(
    token: string,
    permissions: string,
    userName?: string,
  ): Observable<void> {
    this.persist = this.DEFAULT_AUTH_PERSIST;
    return this.serviceLogin(
      id => this.facebookDao.login(token, permissions, id),
      'facebook',
    );
  }

  loginGoogle(
    token: string,
    permissions: string,
    userName?: string,
  ): Observable<void> {
    this.persist = this.DEFAULT_AUTH_PERSIST;
    return this.serviceLogin(id => this.googleDao.login(token, id), 'google');
  }

  loginApple(
    token: string,
    permissions: string,
    userName?: string,
  ): Observable<void> {
    this.persist = this.DEFAULT_AUTH_PERSIST;
    return this.deviceService.getDeviceId().pipe(
      first(),
      switchMap(deviceId =>
        this.serviceLogin(
          id => this.appleDao.login(token, deviceId, id, userName),
          'apple',
        ),
      ),
    );
  }

  logout(): void {
    this.deviceService
      .unregister()
      .pipe(finalize(() => this.logoutNoUnregister()))
      .subscribe();
  }

  logoutNoUnregister(): void {
    this.token = null;
    this.persist = false;
    this.sessionInterceptors.token = null;
    this.deleteGlobalCookie();
    this.router.navigate([
      this.responsiveService.isDesktop()
        ? '/'
        : `/m/${environment.locale.routes.mobile.init}`,
    ]);
    this.credentialStorageService.removeToken();
    this.loggedIn.next(false);
    this.userLogoutEvent.emit();
  }

  setCredentials(token: string): void {
    this.token = token;
    this.sessionInterceptors.token = token;
    this.credentialStorageService.setToken(token, this.persist);
  }

  getCredentials(): {username: string; password: string; remember: boolean} {
    let credentials = b64_to_utf8(this.token).split(':');
    return {
      username: credentials[0],
      password: credentials[1],
      remember: this.persist,
    };
  }

  restore(): boolean {
    let token: string = this.credentialStorageService.getToken();
    if (token) {
      this.token = token;
      this.persist = this.DEFAULT_AUTH_PERSIST;
      this.sessionInterceptors.token = token;
      this.loggedIn.next(true);
      this.insertGlobalCookie();
      this.userRestoreEvent.emit();
    } else {
      this.loggedIn.next(false);
    }

    return !!token;
  }

  isLoggedIn(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }

  private serviceLogin(
    backend: (installationId) => Observable<any>,
    service?: 'google' | 'facebook' | 'apple',
    credentials?: {
      username: string;
      password: string;
    },
    logAttempts: boolean = false,
  ): Observable<void> {
    if (logAttempts && this.checkBlocked()) {
      return throwError(() => ({
        key: FailedAuthErrorType.BLOCKED,
        data: this.failedAuth,
      }));
    }

    return this.installationService.idAsync.pipe(
      switchMap(id => backend(id)),
      map((res: LoginResponse) => {
        const username = credentials ? credentials.username : res.mail;
        const password = credentials ? credentials.password : res.password;
        let token = BasicAuth.getToken(username, password);
        this.setCredentials(token);
        this.sessionInterceptors.token = token;
        this.loggedIn.next(true);
        this.insertGlobalCookie();
        this.measuringDao.sendTagManagerEvent(
          res.registered
            ? !service
              ? 'register_basic'
              : `register_basic_${service}`
            : 'login',
        );
        this.userLoginEvent.emit();
      }),
      // handle login attempts
      catchError(err => {
        if (logAttempts) {
          // failed attempt
          if (err.status === 400 || err.status === 404) {
            this.logAttempt();

            return throwError(() => ({
              key:
                this.failedAuth.attempts <= 0
                  ? FailedAuthErrorType.BLOCKED
                  : FailedAuthErrorType.ATTEMPT,
              data: this.failedAuth,
            }));
          }

          // blocked attempt
          if (err.status === 403 || err.status === 405) {
            this.updateFailedAuth({
              attempts: 0,
            });

            return throwError(() => ({
              key: FailedAuthErrorType.BLOCKED,
              data: this.failedAuth,
            }));
          }
        }

        return throwError(() => err);
      }),
      map(() => {
        this.resetAttempts();
      }),
      // fetch rest of the data
      switchMap(() => this.userService.getData()),
      filter(u => !!u),
      first(),
      switchMap(() => this.taskManager.executeInContext(TaskContext.LOGIN)),
      mapTo(void 0),
    );
  }

  private insertGlobalCookie(): void {
    if (!this.cookieService.check('webapp_logged')) {
      this.cookieService.set(
        'webapp_logged',
        '1',
        2000 /* Expires never*/,
        '/',
        '.tulotero' + environment.locale.domain,
      );
    }
  }

  private deleteGlobalCookie(): void {
    this.cookieService.delete(
      'webapp_logged',
      '/',
      '.tulotero' + environment.locale.domain,
    );
  }

  private loadFailedAuthData(): void {
    try {
      const data = JSON.parse(
        localStorage.getItem(this.failedAuthKey),
      ) as FailedAuthData;
      this.updateFailedAuth(data);
    } catch (e) {
      this.resetAttempts();
    }
  }

  private saveFailedAuthData(): void {
    localStorage.setItem(this.failedAuthKey, JSON.stringify(this.failedAuth));
  }

  private updateFailedAuth(data: Partial<FailedAuthData>): void {
    const now = Date.now();

    let attempts = data.attempts || 0;
    let timestamp = data.timestamp;
    let timeoutTimestamp = data.timeoutTimestamp || 0;

    // midnight reset
    if (timestamp) {
      // check if it's the same day
      const date = new Date(timestamp);
      const dateTomorrow = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + 1,
      );

      // reset values if it's past midnight
      if (dateTomorrow.getTime() <= now) {
        attempts = this.failedAuthLimit;
        timeoutTimestamp = 0;
      }
    }

    // set timeout if there are no attempts left
    if (attempts <= 0) {
      if (!timeoutTimestamp) {
        timeoutTimestamp = now + this.failedAuthTimeout;
      } else if (timeoutTimestamp <= now) {
        // reset expired timeout
        attempts = this.failedAuthLimit;
        timeoutTimestamp = 0;
      }
    }

    // update data
    this.failedAuth = {
      attempts: attempts,
      timestamp: now,
      timeoutTimestamp: timeoutTimestamp,
    };

    this.saveFailedAuthData();
  }

  private logAttempt(): void {
    const attempts = this.failedAuth.attempts - 1;

    this.updateFailedAuth({
      attempts: attempts <= 0 ? 0 : attempts,
    });
  }

  private resetAttempts(): void {
    this.updateFailedAuth({
      attempts: this.failedAuthLimit,
    });
  }

  private checkBlocked(): boolean {
    if (this.failedAuth.attempts <= 0) {
      if (this.failedAuth.timeoutTimestamp <= Date.now()) {
        this.resetAttempts();
        return false;
      }

      return true;
    }

    return false;
  }
}
