import {NavigationExtras} from '@angular/router';
import {Observable, ReplaySubject, Subject} from 'rxjs';

import {ResponsiveService} from '../responsive/responsive.service';

/**
 * Generic service to open and close a prompt as needed.
 */
export abstract class PromptService {
  protected exitRoute: string;

  protected marked = false;

  private processObservable: Subject<void> = new ReplaySubject();

  constructor(protected responsiveService: ResponsiveService) {}

  protected abstract shouldOpen(): boolean | Promise<boolean>;

  protected abstract openDesktop(
    exitRoute: string,
    navigationExtras?: NavigationExtras,
  ): void;

  protected abstract openMobile(
    exitRoute: string,
    navigationExtras?: NavigationExtras,
  ): void;

  protected abstract navigate(
    strings: string[],
    navigationExtras: NavigationExtras,
  ): Promise<boolean>;

  /**
   * Opens the prompt if it should be openned.
   *
   * If the prompt should not be openned it will redirect to the given route
   * instead.
   */
  openPrompt(
    exitRoute: string,
    navigationExtras?: NavigationExtras,
  ): Observable<any> {
    this.exitRoute = exitRoute;
    this.marked = false;

    const open = this.shouldOpen();
    const shouldOpen: Promise<boolean> =
      open instanceof Promise ? open : Promise.resolve(open);
    shouldOpen.then(hasOpen => {
      if (!hasOpen) {
        this.navigate([exitRoute], navigationExtras).then(() =>
          this.processObservable.next(),
        );
        return this.processObservable;
      }
      if (this.responsiveService.isDesktop()) {
        this.openDesktop(exitRoute, navigationExtras);
      } else {
        this.openMobile(exitRoute, navigationExtras);
      }
    });
    return this.processObservable;
  }

  /**
   * Exits the prompt navigating to the route specified on openning (if any)
   * or to the default one provided.
   */
  exitPrompt(
    defaultRoute: string,
    navigationExtras?: NavigationExtras,
    processCompleted = true,
  ): void {
    this.navigate([this.exitRoute || defaultRoute], navigationExtras);
    if (processCompleted) {
      this.processObservable.next();
    }
  }

  /**
   * Flags the prompt to be openned on next try.
   */
  markForOpen(): void {
    this.marked = true;
  }

  /**
   * Check prompt is marked
   */
  isMarked(): boolean {
    return this.marked;
  }

  /**
   * Opens the prompt if it was marked.
   */
  openPromptIfMarked(
    exitRoute: string,
    navigationExtras?: NavigationExtras,
  ): Observable<any> {
    if (this.marked) {
      return this.openPrompt(exitRoute, navigationExtras);
    } else {
      this.navigate([exitRoute], navigationExtras);
      return this.processObservable;
    }
  }

  /**
   * Opens the prompt if it was marked.
   */
  resolve(): void {
    this.processObservable.next();
  }
}
