import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

import {SessionInterceptorService} from './session-interceptor.service';

@Injectable()
export class UnauthorizationInterceptor implements HttpInterceptor {
  constructor(private sessionService: SessionInterceptorService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(event => {
        if (
          event instanceof HttpErrorResponse &&
          this.sessionService.isLoggedIn() &&
          req.headers.has('Authorization') &&
          event.status === 401
        ) {
          const regexp = new RegExp('^(?:[a-z]+:)?//', 'i');
          if (regexp.test(req.url)) {
            const url = new URL(req.url);
            const urlEndpoint = new URL(environment.endpoint);
            if (url.hostname === urlEndpoint.hostname) {
              this.sessionService.logout();
            }
          } else {
            // Check /backend-proxy-es
            if (req.url.indexOf(environment.endpoint) === 0) {
              this.sessionService.logout();
            }
          }
        }
        return throwError(() => event);
      }),
    );
  }
}
