import {Injectable} from '@angular/core';
import {BoothViewDataService} from './booth-view-data.service';
import {
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  takeUntil,
} from 'rxjs';
import {Booth} from './data/booth';

@Injectable()
export class BoothLocalViewDataService extends BoothViewDataService {
  /** Starts the datasource logic ops and return main observable
   *
   * @param destroyRef Must to pass destroy ref to finalize obserbable dataSource */
  initDataSource(destroyRef: Observable<any> | Subject<any>, pageSize: number) {
    this.paginationSize = pageSize;
    const filter$ = this.filter$.pipe(distinctUntilChanged());
    const sortByLocation$ = this.geolocationBySort$.pipe(distinctUntilChanged());
    const pagination$ = this.paginationIndex$.pipe(
      filter(index => index > -1),
      distinctUntilChanged(),
    );

    const dataFiltered$ = combineLatest([this.data$, filter$]).pipe(
      map(([data, filterData]) => {
        data = data || [];
        if (filterData) {
          data = data.filter(booth => this.filterBooth(booth, filterData));
        }
        return data;
      }),
    );

    const sortData$ = combineLatest([dataFiltered$, sortByLocation$]).pipe(
      map(([data, positionToSort]) => {
        data = data || [];
        if (positionToSort) {
          data.forEach((booth: Booth) => {
            const distance = this.geolocationService.getDistance(
              {latitude: +booth.latitude, longitude: +booth.longitude},
              positionToSort.coords,
            );
            booth.distance = distance;
          });
          data.sort((a, b) => a.distance - b.distance);
        }
        return data;
      }),
    );

    const dataPaginated$ = combineLatest([sortData$, pagination$]).pipe(
      map(([data, pageIndex]) => {
        data = data || [];
        data = data.slice(
          pageIndex * this.paginationSize,
          pageIndex * this.paginationSize + this.paginationSize,
        );
        return data;
      }),
    );

    if (this.dataSourceSubscription) {
      this.dataSourceSubscription.unsubscribe();
    }
    this.boothsDao.init();
    this.dataSourceSubscription = dataPaginated$
      .pipe(
        takeUntil(destroyRef),
        finalize(() => {
          this.boothsDao.destroy();
          this.dataSourceSubscription = null;
        }),
      )
      .subscribe(data => this.dataSource.next(data));

    if (this.paginationIndex === -1) {
      this.loadMore().subscribe();
    }
    return this.dataSource.asObservable();
  }
}
