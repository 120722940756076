import {Serializable, SerializableProperty} from 'common';

import {StatePostalCode} from './state-postal-code';
import {PaymentCardType} from './payment-card-type';
import {StateRetailer} from './state-retailer';
import {TicketCancellationLimitsConfig} from './ticket-cancellation-limits-config';

class StateInternal {
  name: string;

  code: string;

  available: boolean;

  @SerializableProperty(StatePostalCode)
  postalCode: StatePostalCode;

  @SerializableProperty(StateRetailer)
  officialRetailer?: StateRetailer;

  allowedLoad = true;

  paymentTypesAllowed: Array<PaymentCardType> = Object.values(
    PaymentCardType,
  ) as Array<PaymentCardType>;

  boletosCancelLimit: TicketCancellationLimitsConfig;
}

export class State extends Serializable(StateInternal) {
  static createFromBackend(obj: any, endpointObj?: any) {
    let state = new State();

    state.name = obj.name;
    state.code = obj.code;
    state.available = obj.available;
    state.postalCode = StatePostalCode.createFromBackend(obj.postalCode);
    if (obj.hasOwnProperty('officialRetailer')) {
      state.officialRetailer = StateRetailer.createFromBackend(obj.officialRetailer);
    }
    if (endpointObj) {
      if (endpointObj.credit) {
        if (
          endpointObj.credit.creditCard &&
          endpointObj.credit.creditCard.debitOnly
        ) {
          // Add payment states
          // debitOnly = [CO, MN]
          const debitOnlyStates = new Set(endpointObj.credit.creditCard.debitOnly);
          const {code} = state;
          const isDebitOnly = debitOnlyStates.has(code);
          state.paymentTypesAllowed =
            isDebitOnly || !state.available
              ? [PaymentCardType.DEBIT]
              : [PaymentCardType.DEBIT, PaymentCardType.CREDIT];
        }

        if (endpointObj.credit.regionsAllowedToLoad) {
          const statesAllowedToLoad = new Set(
            endpointObj.credit.regionsAllowedToLoad,
          );
          const {code} = state;
          state.allowedLoad = statesAllowedToLoad.has(code);
        }
      }

      if (
        endpointObj.boletosCancelLimit &&
        Array.isArray(endpointObj.boletosCancelLimit)
      ) {
        const boletosCancelLimitBackendObject =
          endpointObj.boletosCancelLimit.find(
            boletoConfig => boletoConfig.region === state.code,
          ) ??
          endpointObj.boletosCancelLimit.find(
            boletoConfig => boletoConfig.region === 'ALL',
          );
        state.boletosCancelLimit = TicketCancellationLimitsConfig.createFromBackend(
          boletosCancelLimitBackendObject,
        );
      }
    }

    return state;
  }

  static fromJSON(json: any): State {
    if (typeof json === 'string') {
      return JSON.parse(json, State.reviver) as State;
    } else if (json !== undefined && json !== null) {
      let tmp = Object.create(State.prototype);
      return Object.assign(tmp, json);
    } else {
      return json;
    }
  }
}
