import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ENVIRONMENT, LocaleService, LocalStorage, Logger} from 'common';
import {Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

import {AbstractDao} from '../common/model/abstract.dao';

import {PrivateInfo} from './private-info/private-info';
import {PublicInfo} from './public-info/public.info';

@Injectable({providedIn: 'root'})
export class BackendDao extends AbstractDao {
  cachedBooths = null;

  constructor(
    protected http: HttpClient,
    protected localStorage: LocalStorage,
    protected locale: LocaleService,
    protected logger: Logger,
    @Inject(ENVIRONMENT) protected environment: Record<string, any>,
  ) {
    super();
  }

  allInfoPublic(): Observable<PublicInfo> {
    const languageCode = this.locale.getLocale().replaceAll('-', '_');
    let endpointCommon: string = this.environment.endpointCommon[languageCode];
    if (!endpointCommon) {
      this.logger.error(
        // eslint-disable-next-line max-len
        `CRITICAL - Current Language ${languageCode} not available for endpointCommon. Requesting ALL JSON`,
      );
      endpointCommon = this.environment.endpointCommon.ALL;
    }
    const httpObservable = this.http.get(endpointCommon).pipe(
      switchMap((res: any) => {
        if (res.endpoint.administrations) {
          return this.getBooths(res.endpoint.adminsUrl).pipe(
            map(booths => {
              res.administraciones = booths;
              return res;
            }),
          );
        } else {
          return of(res);
        }
      }),
    );

    return this.postProcessAllInfoPublic(httpObservable);
  }

  allInfo(lastUpdate?: number): Observable<PrivateInfo> {
    let url = '/users/allinfo/v2';

    if (lastUpdate) {
      url += '?lastUpdate=' + lastUpdate;
    }

    const httpObservable = this.http
      .get(this.baseUrl + url, {observe: 'response'})
      .pipe(
        switchMap((res: HttpResponse<any>) => {
          if (res.status !== 204) {
            if (res.body.endpoint.administrations) {
              return this.getBooths(res.body.endpoint.adminsUrl).pipe(
                map(booths => {
                  res.body.administraciones = booths;
                  return res.body;
                }),
              );
            } else {
              return of(res.body);
            }
          } else {
            return of(res);
          }
        }),
      );
    return this.postProcessAllInfo(httpObservable);
  }

  getBooths(adminsUrl: string): Observable<Array<any>> {
    if (
      this.cachedBooths &&
      Date.now().valueOf() -
        +this.localStorage.getItem(
          this.environment.localStorageKeys.boothsLastDate,
        ) <
        this.environment.allInfo.boothCacheDuration
    ) {
      return of(this.cachedBooths);
    }

    return this.http.get(adminsUrl).pipe(
      catchError(err => {
        this.logger.error('Error on getBooths', err);
        // avoid freeze webapp when booths are not available, for example with cors error
        return of([]);
      }),
      map(booths => {
        const list = booths as Array<any>;
        this.cachedBooths = list;
        this.localStorage.setItem(
          this.environment.localStorageKeys.boothsLastDate,
          Date.now().toString(),
        );

        return list;
      }),
    );
  }

  protected postProcessAllInfoPublic(
    httpObservable: Observable<any>,
  ): Observable<PublicInfo> {
    return httpObservable.pipe(
      map(response => PublicInfo.createFromBackend(response)),
    );
  }

  protected postProcessAllInfo(
    httpObservable: Observable<any>,
  ): Observable<PrivateInfo> {
    return httpObservable.pipe(
      // TODO remove
      map(response =>
        response.genericDescriptors ? this.mock(response) : response,
      ),
      // -------
      map(response =>
        response instanceof HttpResponse
          ? null
          : PrivateInfo.createFromBackend(response),
      ),
    );
  }

  // TODO REMOVE
  protected mock(response): any {
    response.genericDescriptors.forEach(desc => {
      desc.uiInfo.boardIndexOutside = true;

      if (desc.juego === 'PROGOL_PLAY') {
        desc.types[0].allowedValues.forEach(choice => (choice.header = undefined));
        desc.types[1].allowedValues.forEach(choice => (choice.header = undefined));
      }

      if (desc.juego === 'PROTOUCH_PLAY') {
        desc.types[1].allowedValues.forEach(choice => (choice.header = undefined));
      }
      //
      //   if (desc.juego === 'MELATE_RETRO') {
      //     desc.types[0].uiInfo = {
      //       highlightColor: '#f5f5f5',
      //       length: 8,
      //    };
      //  }
      //
      //   if (desc.juego === 'CHISPAZO') {
      //     desc.types[0].uiInfo = {
      //       highlightColor: '#f5f5f5',
      //       length: 7,
      //    };
      //  }
      //
      //   if (desc.juego === 'TRIS') {
      //     desc.types[0].uiInfo = {
      //       highlightColor: '#f5f5f5',
      //       length: 10,
      //    };
      //  }
      //
      //   // if (desc.juego === 'POWERBALL' || desc.juego === 'TEXAS_TWO_STEP'
      //   //     || desc.juego === 'MEGAMILLIONS' ) {
      //   //   desc.types[0].uiInfo.fullscreen = true;
      //   //   desc.types[0].uiInfo.highlightColor = '#f5f5f5';
      //   //
      //   //   desc.types[1].uiInfo.fullscreen = true;
      //   //   desc.types[1].uiInfo.modal = true;
      //   //   desc.types[1].uiInfo.highlightColor = '#f5f5f5';
      //   //
      //   //   // desc.bets[0].types[0].numMaxColumns = 1;
      //   //}
    });

    return response;
  }
}
