import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CustomHttpUrlEncondingCodec, isNumeric} from 'common';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {CoordinatePoint} from '../../../geolocation/coordinate-point';
import {CombinationType} from '../../combination/data/combination-type';
import {CombinationTypeValue} from '../../combination/data/combination-type-declaration';
import {CombinationValue} from '../../combination/data/combination-value';
import {GameMetadata} from '../../game-metadata/data/game-metadata';

import {LotteryTicketPaginationData} from './lottery-ticket-pagination-data';
import {LotteryDao} from './lottery.dao';

// TODO · ADAPTER · remove
@Injectable()
export class LotteryDaoAdapter extends LotteryDao {
  protected getLotteryTicketsGeneric(
    url: string,
    raffleId: number,
    gameMetadata: GameMetadata,
    index = 0,
    combinationTypes?: Array<CombinationTypeValue>,
    minNumber?: number,
    location?: CoordinatePoint,
    companySearch = false,
    includeReservations = false,
  ): Observable<LotteryTicketPaginationData> {
    if (!gameMetadata.upgrade) {
      return super.getLotteryTicketsGeneric(
        url,
        raffleId,
        gameMetadata,
        index,
        combinationTypes,
        minNumber,
        location,
        companySearch,
        includeReservations,
      );
    }
    // This codec replaces the default angular one wich encodes the url and
    // then decodes some characters like '+', in this case the backend expects
    // the '+' encoded in the adminId field.
    let params = new HttpParams({
      encoder: new CustomHttpUrlEncondingCodec(),
    }).append('firstResult', index.toString());

    if (combinationTypes) {
      combinationTypes
        .filter(
          (type: CombinationType<CombinationValue>) => type.value[0].value !== '',
        )
        .forEach(
          (type: CombinationType<CombinationValue>) =>
            (params = params.append(type.typeId, type.value[0].value)),
        );
    }

    if (minNumber) {
      params = params.append('cantidadMinima', minNumber.toString());
    }

    if (location && isNumeric(location.latitude) && isNumeric(location.longitude)) {
      params = params.append('latitud', location.latitude.toString());
      params = params.append('longitud', location.longitude.toString());
    }

    if (companySearch) {
      params = params.append('loteriaEmpresa', 'true');
    }

    if (includeReservations) {
      params = params.append('includeReservations', 'true');
    }

    return this.http.get(this.baseUrl + url + raffleId, {params: params}).pipe(
      map((obj: any) => this.adaptPaginationData(obj, raffleId)),
      map((obj: any) => LotteryTicketPaginationData.createFromBackend(obj)),
    );
  }

  private adaptPaginationData(
    oldData: any,
    raffleId: number,
  ): Record<string, unknown> {
    return {
      totalDecimosEnSorteo: oldData.totalDecimosEnSorteo,
      totalStockEnSorteo: oldData.totalStockEnSorteo,
      firsResult: 0,
      sorteoId: raffleId,
      numbers: oldData.decimos.map(decimo => this.adaptLotteryTicket(decimo)),
    };
  }

  // noinspection JSMethodCanBeStatic
  private adaptLotteryTicket(oldTicket: any): Record<string, unknown> {
    return {
      amount: oldTicket.cantidad,
      reservation: oldTicket.reservation,
      types: [
        {typeId: 'numero', type: 'NUMBER', value: ['' + oldTicket.numero]},
        {
          typeId: 'adminId',
          type: 'ALPHANUMERIC',
          value: [oldTicket.adminId],
        },
      ],
    };
  }
}
