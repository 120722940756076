import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {fromEvent, Subject} from 'rxjs';
import {debounceTime, first, map, takeUntil, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AlertsService, ResponsiveService} from 'common';

import {BoothSelectedService} from '../../booth-selected.service';
import {BoothsSearchViewService} from '../../booths-search-view.service';
import {LotteryBooth} from '../../data/lottery-booth';
import {SessionService} from '../../../user/auth/session.service';
import {environment} from '~environments/environment';
import {BoothViewDataService} from '../../booth-view-data.service';
import {LotteryBoothService} from '../../data/lottery-booth.service';
import {GeolocatedLotteryBooth} from '../../data/geolocated-lottery-booth';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
@Component({template: 'no-ui'})
export abstract class LotteryBoothsListBaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('search', {static: true})
  searchInput: ElementRef;

  booths: Array<LotteryBooth>;
  loading: boolean;
  watchingGeoLocation: boolean;

  @Input()
  mode: 'favorite' | 'select';

  @Input()
  showContactInfo = true;

  @Input()
  listMode = false;

  useBoothService = false;

  protected destroySubject = new Subject<void>();

  constructor(
    protected boothsSearchViewService: BoothsSearchViewService,
    protected boothSelectedService: BoothSelectedService,
    protected sessionService: SessionService,
    protected alertsService: AlertsService,
    protected router: Router,
    protected responsiveService: ResponsiveService,
    protected cdr: ChangeDetectorRef,
    protected lotteryBoothService: LotteryBoothService,
    protected boothViewDataService?: BoothViewDataService,
  ) {
    this.useBoothService = !!environment.features.booths.remoteBooths;
  }

  protected abstract updateViewPort(): void;
  protected abstract getPaginationSize(): number;

  ngOnInit() {
    if (this.useBoothService) {
      this.boothViewDataService
        .initDataSource(this.destroySubject, this.getPaginationSize())
        .pipe(
          takeUntil(this.destroySubject),
          tap(
            () =>
              (this.watchingGeoLocation =
                this.boothViewDataService.geolocationSortEnabled),
          ),
          map(booths => {
            if (this.boothViewDataService.geolocationSortEnabled) {
              return booths.map(
                booth =>
                  new GeolocatedLotteryBooth(
                    LotteryBooth.createFromBooth(booth),
                    booth.distance,
                  ),
              );
            } else {
              return booths.map(booth => LotteryBooth.createFromBooth(booth));
            }
          }),
          tap(booths => this.lotteryBoothService.setData(booths)),
        )
        .subscribe(booths => {
          this.booths = booths;
          this.updateViewPort();
          this.cdr.markForCheck();
        });

      this.boothViewDataService.loading
        .pipe(takeUntil(this.destroySubject))
        .subscribe(loading => {
          this.loading = loading;
          this.cdr.detectChanges();
        });
    } else {
      // deprecated remove after WEB-2905
      this.boothsSearchViewService
        .getFavoriteList()
        .pipe(takeUntil(this.destroySubject))
        .subscribe(booths => {
          this.booths = booths;
          this.cdr.markForCheck();
        });
    }
  }

  ngAfterViewInit() {
    if (this.searchInput) {
      fromEvent(this.searchInput.nativeElement, 'keyup')
        .pipe(debounceTime(500), takeUntil(this.destroySubject))
        .subscribe((event: any) => {
          if (this.useBoothService) {
            this.boothViewDataService.setFilter({
              search: event.target.value,
            });
          } else {
            this.boothsSearchViewService.searchSubject.next(event.target.value);
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  trackByBoothId(index: number, booth: LotteryBooth): string {
    return booth.id;
  }

  onBoothSelected(booth) {
    this.sessionService
      .isLoggedIn()
      .pipe(first())
      .subscribe(isLogged => {
        if (isLogged) {
          this.boothSelectedService.selectedBooth.next(booth);
        } else {
          this.alertsService.notifyInfo({
            key: 'games.play.warningRequiredData.registrationRequired',
          });
          const routeMap = environment.locale.routes;
          this.router.navigate([
            (this.responsiveService.isDesktop() ? '/' : '/m/') + routeMap.register,
          ]);
        }
      });
  }

  protected loadMore() {
    if (this.useBoothService) {
      this.boothViewDataService.loadMore().subscribe();
    } else {
      throw new Error('Default service not having pagination implementation');
    }
  }
}
