import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {Toggleable, toggleableConfig} from 'common';
import {Observable} from 'rxjs';

import {BoothsFavoriteViewService} from '../../booths-favorite-view.service';
import {LotteryBooth} from '../../data/lottery-booth';
import {DistanceUnit} from 'common';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-lottery-booth-item',
  templateUrl: './booth-item.component.html',
  styleUrls: ['./booth-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [toggleableConfig(LotteryBoothItemComponent)],
})
export class LotteryBoothItemComponent extends Toggleable implements OnChanges {
  @Input()
  lotteryBooth: LotteryBooth;

  @Input()
  showDistance = true;

  @Input()
  showContactInfo = true;

  @Input()
  listMode = false;

  @Input()
  enableToggle = true;

  @Input()
  showMenu = false;

  @Input()
  enableIconFav = true;

  @Input()
  distanceUnit: DistanceUnit;

  @Output()
  toggleMenu = new EventEmitter<void>();

  get fullAddress(): string {
    return `${this.lotteryBooth?.address ?? ''} ${this.lotteryBooth?.city ?? ''} ${
      this.lotteryBooth.state ? this.lotteryBooth.state : ''
    }`;
  }

  get favoriteBooth(): Observable<string> {
    return this.boothsFavoriteViewService.favoriteBooth;
  }

  get boothDescription(): string {
    const address = this.lotteryBooth.address ? this.lotteryBooth.address : '';
    const city = this.lotteryBooth.city ? this.lotteryBooth.city : '';
    const zipCodeState = `${this.lotteryBooth.zipCode} ${this.lotteryBooth.state}`;

    let location = `${address}${address && city ? ', ' : ''}${city}`;

    location += address || city ? '. ' : '';

    return location + zipCodeState;
  }

  constructor(
    protected cdr: ChangeDetectorRef,
    private boothsFavoriteViewService: BoothsFavoriteViewService,
  ) {
    super(cdr);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('lotteryBooth') && this.toggleState) {
      this.close();
    }
  }

  @HostListener('click')
  toggleDescription() {
    if (this.enableToggle && this.showContactInfo) {
      if (this.toggleState) {
        this.close();
      } else {
        this.open();
      }
    }
  }

  onToggleMenu() {
    this.toggleMenu.emit();
  }
}
