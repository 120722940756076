<tl-modal-dialog type="void" [disableContentPadding]="true">
  <div class="tl-booth-selector-modal__content">
    <div class="tl-booth-selector-modal__list">
      <tl-lottery-booths-list
        mode="select"
        class="with-map"
        [geolocationEnabled]="!geolocationViewService.permissionDenied"
        [currentBoothId]="selectedId">
      </tl-lottery-booths-list>
      <div
        class="tl-booth-selector-modal__select-button-container section-shadow-top">
        <button
          class="tl-booth-selector-modal__select-button btn btn-type-4"
          type="button"
          (click)="selectBooth()"
          [disabled]="!selectedId">
          {{ 'administrationOffice.buttonChange' | i18n }}
        </button>
      </div>
    </div>
    <div class="tl-booth-selector-modal__map">
      <tl-google-map
        #googleMap
        [initMapArgs]="initMapArgs"
        [skipCenterOnSelection]="true"
        (dragEnd)="filterListBooths()"
        (zoomChange)="filterListBooths()"
        (markerSelected)="onMarkerSelected($event)"
        (init)="mapReady.emit()">
        <tl-google-map-controls
          (closeMap)="activeModal.dismiss()"
          (requestLocation)="boothList.toogleLocate()">
        </tl-google-map-controls>
      </tl-google-map>
    </div>
  </div>
</tl-modal-dialog>
