import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  NgZone,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {SwUpdate} from '@angular/service-worker';
import {
  FacebookService,
  GoogleAuthService,
  MessageHookAsapDisplayerService,
  ResponsiveService,
  switchFilter,
  TranslationsService,
} from 'common';
import {fromEvent, merge, Observable, of} from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  first,
  skip,
  switchMap,
} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {AppViewService} from './app-view.service';
import {PrivateInfoAutoSyncService} from './backend/private-info/private-info-auto-sync.service';
import {PrivateInfoService} from './backend/private-info/private-info.service';
import {PublicInfoService} from './backend/public-info/public.info.service';
import {InstallationService} from './marketing/installation.service';
import {NotificationsSubscriptionManagerService} from './notifications/notifications-subscription-manager.service';
import {PwaInstallService} from './pwa/pwa-install.service';
import {PwaForceUpdateService} from './pwa/update/pwa-force-update.service';
import {SessionService} from './user/auth/session.service';
import {TicketsCanceledViewService} from './games/tickets/tickets-canceled/tickets-canceled-view.service';
import {SubscriptionsNotExcludedViewService} from './games/tickets/subscriptions-not-executed/subscriptions-not-excluded-view.service';
import {UpdateStateAppListenerService} from './states/data/update-state-app-listener.service';
import {CheckFailedWithdrawalsService} from './money/request-withdrawal/check-failed-withdrawals.service';
import {UserLocaleListenerService} from './i18n/user-locale-listener.service';

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  animationsDisabled = environment.animationsDisabled;

  boot = false;

  isUpdateRequired = false;

  forceupdate: Observable<boolean>;

  prebootMessageKey = '';

  private focusAfterCheckUpdates = new EventEmitter();

  get isMobile() {
    return this.responsiveService.isMobile();
  }

  constructor(
    private appViewService: AppViewService,
    private cdr: ChangeDetectorRef,
    private facebookService: FacebookService,
    private googleAuthService: GoogleAuthService,
    private installationService: InstallationService,
    private checkFailedWithdrawalsService: CheckFailedWithdrawalsService,
    // This one is not possible to be injected through APP_INITIALIZER
    // see: https://github.com/ng-bootstrap/ng-bootstrap/issues/3719
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // @ts-ignore
    private messageHookAsapDisplayerService: MessageHookAsapDisplayerService,
    private notificationsSubscription: NotificationsSubscriptionManagerService,
    private privateInfoAutoSync: PrivateInfoAutoSyncService,
    private privateInfoService: PrivateInfoService,
    private publicInfoService: PublicInfoService,
    private pwaForceUpdateService: PwaForceUpdateService,
    private pwaInstallPromptService: PwaInstallService,
    private renderer: Renderer2,
    private responsiveService: ResponsiveService,
    private router: Router,
    private sessionService: SessionService,
    private swUpdate: SwUpdate,
    private translations: TranslationsService,
    private ticketsCanceledViewService: TicketsCanceledViewService,
    private subscriptionsNotExcludedViewService: SubscriptionsNotExcludedViewService,
    private userLocaleListenerService: UserLocaleListenerService,
    @Inject('window') private window: Window,
    private zone: NgZone,
    @Optional() private updateStateAppListenerService: UpdateStateAppListenerService,
  ) {
    this.forceupdate = this.pwaForceUpdateService.updating;

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.isUpdateRequired = true;
        this.cdr.markForCheck();
      });
      this.pwaInstallPromptService.initialize();
    }

    if (environment.showMessageHooks) {
      this.messageHookAsapDisplayerService.init();
    }
    if (environment.geolocation.enableAppState) {
      this.updateStateAppListenerService.init();
    }
    if (environment.features.multiLanguage) {
      this.userLocaleListenerService.init();
    }
  }

  ngOnInit() {
    this.pwaForceUpdateService.hasUpdate().subscribe(needUpdate => {
      if (needUpdate) {
        this.prebootMessageKey = 'global.updateWebInProgress';
        this.cdr.markForCheck();
        this.pwaForceUpdateService.update();
      } else {
        this.bootApp();
      }
    });
  }

  private bootApp() {
    this.translations.load().subscribe();
    this.initialize();
    if (!this.sessionService.restore()) {
      this.publicInfoService.loadAllInfo().subscribe(() => this.bootFinish());
    }
  }

  private bootFinish() {
    setTimeout(() => {
      this.boot = true;
      this.cdr.markForCheck();
    }, 100);
    setTimeout(() => this.installationService.initialize(), 1000);
  }

  private initialize() {
    this.appViewService.trackPromoCode();
    this.appViewService.checkDataIntegrity();
    this.reloadAllInfoRegularly();
    this.updateAppWhenUserComeIn();

    this.sessionService.userLoginEvent.subscribe(() => {
      if (this.sessionService.persist) {
        this.privateInfoService.enableAutoStore();
      }
      this.privateInfoService.load().subscribe(() => {
        this.privateInfoAutoSync.enableTimer();
      });
    });

    this.sessionService.userRestoreEvent.subscribe(() => {
      this.privateInfoService.enableAutoStore();
      this.privateInfoService
        .restore(this.responsiveService.isDesktop())
        .subscribe(() => {
          this.privateInfoAutoSync.enableTimer();
          this.bootFinish();
        });
      this.updateAllInfo();
    });

    this.sessionService.userLogoutEvent.subscribe(() => {
      this.privateInfoService.disableAutoStore();
      this.privateInfoService.unload();
      this.privateInfoAutoSync.clearTimer();
      this.publicInfoService.loadAllInfo().subscribe(() => this.bootFinish());

      // logout from social services
      this.facebookService
        .getLoginStatus()
        .pipe(
          catchError(() => of(false)),
          filter(loggedIn => !!loggedIn),
          switchMap(() => this.facebookService.logout()),
        )
        .subscribe();
      this.googleAuthService.logout().subscribe();
    });

    this.appViewService.initializeAnalytics();
    // wait a bit before making the request to try to improve performance
    setTimeout(() => this.notificationsSubscription.trySendSubscription(), 1000);
    // Just after boot, check updates for quick update.
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate();
    }
    // Wait to login to launch background services
    this.sessionService
      .isLoggedIn()
      .pipe(
        filter(logged => !!logged),
        first(),
      )
      .subscribe(() => {
        this.ticketsCanceledViewService.checkTicketsCanceled();
        this.subscriptionsNotExcludedViewService.checkSubcriptionsNotExecuted();
        this.checkFailedWithdrawalsService
          .checkFailedWithdrawals()
          .subscribe((requestWithdrawal: boolean) => {
            if (requestWithdrawal) {
              this.checkFailedWithdrawalsService.setData([]);
              this.privateInfoService
                .getData()
                .pipe(first())
                .subscribe(privateInfo => {
                  privateInfo.failedWithdrawals = [];
                  this.privateInfoService.setData(privateInfo);
                });
            }
          });
      });
  }

  private updateAppWhenUserComeIn() {
    this.zone.runOutsideAngular(() => {
      this.renderer.listen('window', 'focus', () => {
        if (this.swUpdate.isEnabled) {
          this.pwaForceUpdateService.hasUpdate().subscribe(needUpdate => {
            if (needUpdate) {
              this.prebootMessageKey = 'global.updateWebInProgress';
              this.cdr.markForCheck();
              this.pwaForceUpdateService.update();
            } else {
              this.focusAfterCheckUpdates.emit();
            }
          });
          if (this.isUpdateRequired) {
            this.isUpdateRequired = false;
            this.window.location.reload();
          }

          this.swUpdate.checkForUpdate();
          this.notificationsSubscription.trySendSubscription();
        } else {
          this.focusAfterCheckUpdates.emit();
        }
      });
    });
  }

  private reloadAllInfoRegularly() {
    this.zone.runOutsideAngular(() => {
      // Set routers events to request allInfo on main page
      this.router.events
        .pipe(
          filter(e => e instanceof NavigationEnd),
          // Skip when users is logging and privateInfo is doing a V2 complete
          switchFilter(() => this.sessionService.isLoggedIn().pipe(first())),
          filter(() => !!this.privateInfoService.lastAllInfoCall),
          filter(
            () =>
              (this.router.url === '/' && this.responsiveService.isDesktop()) ||
              (this.router.url ===
                `/m/${environment.locale.routes.mobile.main.games}` &&
                this.responsiveService.isMobile()),
          ),
          skip(1), // skip first time page load
          debounceTime(300),
        ) // prevent allinfo during navigation animation
        .subscribe(() => this.privateInfoAutoSync.sync());

      // Same logic for sync used below
      const trySync = () => {
        if (this.privateInfoAutoSync.shouldSync()) {
          this.privateInfoAutoSync.sync();
        } else {
          this.privateInfoAutoSync.enableTimer();
        }
      };

      // Set listener to request allInfo regularly and when the browser goes online
      // If there is a force update, this is not executed
      merge(this.focusAfterCheckUpdates, fromEvent(this.window, 'online'))
        .pipe(switchFilter(() => this.sessionService.isLoggedIn().pipe(first())))
        .subscribe(() => trySync());

      this.renderer.listen('window', 'blur', () => {
        this.privateInfoAutoSync.clearTimer();
        // Chrome doesn't trigger focus event when phone is more than 15
        // minutes sleeping, android kernel cleans the memory and chrome has to
        // deserialize the state from disk.
        fromEvent(this.window.document, 'click')
          .pipe(
            first(),
            switchFilter(() => this.sessionService.isLoggedIn().pipe(first())),
          )
          .subscribe(() => trySync());
      });
    });
  }

  private updateAllInfo() {
    // In the mobile version, we don't want an update of allInfo
    // to occur when the user accesses the news from a push notification or external url.
    if (this.responsiveService.isMobile()) {
      const routeMap = environment.locale.routes;
      const newsRegexp = new RegExp(
        `\\/m\\/${routeMap.mobile.user}\\/${routeMap.mobile.tuloteroSlides.news}(?:\\/(\\d+))?`,
      );
      this.zone.runOutsideAngular(() => {
        this.router.events
          .pipe(
            filter(e => e instanceof NavigationEnd),
            filter((e: NavigationEnd) => !newsRegexp.test(e.url)),
            first(),
            switchMap(() => this.privateInfoService.update()),
          )
          .subscribe();
      });
    }
  }
}
