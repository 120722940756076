import {Injectable, Injector} from '@angular/core';
import {Observable, zip} from 'rxjs';
import {ModalDialogComponent, ModalDialogLayout, ModalHelperService} from 'common';
import {first, map, switchMap, take} from 'rxjs/operators';
import {StateSubscribeDialogComponent} from './state-subscribe-dialog/state-subscribe-dialog.component';
import {UserService} from '../user/data/user.service';
import {GeolocationViewService} from '../geolocation/geolocation-view.service';
import {StatesService} from './data/states.service';
import {State} from './data/state';

@Injectable()
export class StateModalService {
  constructor(
    private userService: UserService,
    private geolocationViewService: GeolocationViewService,
    private modalHelperService: ModalHelperService,
    private statesService: StatesService,
    public injector: Injector,
  ) {}
  displayLocationError(
    title: string,
    message: string,
    description: string,
    isUserNotifyMeStateAvailable?: boolean,
  ): Observable<void> {
    let modalParams = {
      title: title,
      message: [message, description].filter(txt => txt),
      image: 'assets/img/geolocalizacion/map-states.png',
    };
    return this.openGeolocationNotifyModal(
      modalParams,
      isUserNotifyMeStateAvailable,
    );
  }

  displayLocationStateNotFoundErrorLoad(): Observable<any> {
    const messageKey =
      'notAvailableLoadPlayDialogs.outsideOfUsLoadPlayDialog.loadText';
    return this.displayLocationStateNotFoundError(messageKey);
  }

  displayLocationStateNotFoundErrorPlay(): Observable<any> {
    const messageKey =
      'notAvailableLoadPlayDialogs.outsideOfUsLoadPlayDialog.playText';
    return this.displayLocationStateNotFoundError(messageKey);
  }

  private displayLocationStateNotFoundError(messageKey: string): Observable<any> {
    return this.statesService.getData().pipe(
      first(),
      map((states: Array<State>) =>
        states.filter((state: State) => state.available),
      ),
      map((availableStates: Array<State>) =>
        availableStates.map((state: State) => state.name).join(', '),
      ),
      switchMap((availableStatesString: string) =>
        this.modalHelperService.openOkModal(ModalDialogComponent, {
          componentParams: {
            type: 'ok',
            title: {
              key: 'notAvailableLoadPlayDialogs.outsideOfUsLoadPlayDialog.title',
            },
            message: {
              key: messageKey,
              keyData: {availableStates: availableStatesString},
            },
            accept: {key: 'global.gotIt'},
            footer: {
              key: 'payments.anyQuestionsContact',
            },
            closeOnNavigation: true,
            layout: ModalDialogLayout.VERTICAL,
            smallText: true,
            image: 'assets/img/geolocalizacion/map-states.png',
            imageWidth: 96,
          },
        }),
      ),
    );
  }

  private openGeolocationNotifyModal(
    options?,
    isUserNotifyMeStateAvailable?: boolean,
  ) {
    return zip([
      // get email
      this.userService.getData().pipe(
        take(1),
        map(userData => userData.email),
      ),
      // get location
      this.geolocationViewService.requestLocation(),
    ]).pipe(
      switchMap(([email, geolocation]: [string, GeolocationPosition]) => {
        if (isUserNotifyMeStateAvailable === false) {
          // When the user's current state is not available and the user is not
          // subscribed, show the dialog to get notified when it becomes available
          return this.modalHelperService.openModal(StateSubscribeDialogComponent, {
            componentParams: {
              type: 'ok_cancel',
              ...options,
              geoLocation: geolocation,
              email: email,
            },
            modalOptions: {
              modalDialogClass:
                'tl-geolocation-prompt tl-dlg-wrapper-column tl-state-subscribe-modal',
              centered: true,
              injector: this.injector,
            },
          }).closed;
        }

        return this.modalHelperService.openOkModal(ModalDialogComponent, {
          componentParams: {
            ...options,
            type: 'ok',
            accept: {key: 'global.gotIt'},
            footer: {
              key: 'payments.anyQuestionsContact',
            },
            closeOnNavigation: true,
            layout: ModalDialogLayout.VERTICAL,
            smallText: true,
            imageWidth: 96,
          },
        });
      }),
    );
  }
}
