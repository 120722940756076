import {Serializable} from 'common';

export class TicketCancellationLimitsConfigInternal {
  cancelRequest: number;
  cancelConfirmCountdown: number;
}

export class TicketCancellationLimitsConfig extends Serializable(
  TicketCancellationLimitsConfigInternal,
) {
  static createFromBackend(backendObj: any) {
    const ticketCancellationLimits = new TicketCancellationLimitsConfig();

    ticketCancellationLimits.cancelRequest = backendObj.cancelRequest;
    ticketCancellationLimits.cancelConfirmCountdown =
      backendObj.cancelConfirmCountdown;

    return ticketCancellationLimits;
  }
}
