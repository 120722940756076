import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subscription, map} from 'rxjs';

import {AbstractDao} from '../../common/model/abstract.dao';
import {BoothFilterDataSource} from './booth-filter';
import {Booth, BoothRemote} from './booth';
import {BoothsUriMapperService} from './booths-uri-mapper';
@Injectable()
export abstract class BoothsDao extends AbstractDao {
  protected listeners: Subscription;
  constructor(protected http: HttpClient) {
    super();
  }

  abstract init(): void;

  abstract getBooths(
    pagination: {index: number; pageSize: number},
    filter?: BoothFilterDataSource,
  ): Observable<any>;

  destroy(): void {
    if (this.listeners) {
      this.listeners.unsubscribe();
    }
  }
}

@Injectable()
/** Implements after development WEB-2905 completed. To implements all booths logic */
export class BoothsLocalDao extends BoothsDao {
  constructor(
    protected http: HttpClient,
    protected boothsUriMapperService: BoothsUriMapperService,
  ) {
    super(http);
  }

  init(): void {
    this.listeners = new Subscription();
    this.listeners.add(
      this.boothsUriMapperService
        .getData()
        .pipe()
        .subscribe(boothsEndpoint => {
          const endpointGeneric = boothsEndpoint.get('ALL');
          this.baseUrl += endpointGeneric.replace('/rest', '');
        }),
    );
  }

  getBooths(
    pagination: {index: number; pageSize: number},
    filter?: BoothFilterDataSource,
  ): Observable<Array<Booth>> {
    // Implements lotteryBotthService to get the data
    throw new Error(
      'Method not implemented: getBooths requires implementation using.',
    );
  }
}

@Injectable()
export class BoothsRemoteDao extends BoothsDao {
  boothsEndpoint: Map<string, string>;
  endpoint: {state: string; url: string};

  constructor(
    protected http: HttpClient,
    protected boothsUriMapperService: BoothsUriMapperService,
  ) {
    super(http);
  }

  init(): void {
    this.listeners = new Subscription();
    this.listeners.add(
      this.boothsUriMapperService
        .getData()
        .pipe()
        .subscribe(boothsEndpoint => (this.boothsEndpoint = boothsEndpoint)),
    );
  }

  updateStateEndpoint(stateCode: string): void {
    if (this.endpoint?.state !== stateCode) {
      const endpointGeneric = this.boothsEndpoint.get('ALL');
      const endpointState = this.boothsEndpoint.get(stateCode);
      const endpointUrl = endpointState || endpointGeneric;
      this.endpoint = {
        state: stateCode,
        url: this.baseUrl + endpointUrl.replace('/rest', ''),
      };
    }
  }

  getBooths(
    pagination: {index: number; pageSize: number},
    filter?: BoothFilterDataSource,
  ): Observable<Array<Booth>> {
    if (!this.endpoint) {
      throw new Error(
        'Endpoint URL is not defined. Call frist updateStateEndpoint()',
      );
    }
    let params = new HttpParams();

    if (filter) {
      if (filter.search) {
        params = params.append('searchTerms', filter?.search || '');
      }
      if (filter.coords) {
        params = params.append('latitude', filter.coords.lat);
        params = params.append('longitude', filter.coords.long);
        // params = params.append('longitude', -74.006);
        // params = params.append('latitude', 40.7128);
        if (filter.coords.radius) {
          params = params.append('radius', filter.coords.radius); // in meters
        }
      }
    }

    params = params.append('page', pagination.index);
    params = params.append('size', pagination.pageSize);

    return this.http.get(this.endpoint.url, {params: params}).pipe(
      map((res: any) => res.object),
      map((booths: any) =>
        booths ? booths.map(booth => BoothRemote.createFromBackend(booth)) : [],
      ),
    );
  }
}
