import {Injectable} from '@angular/core';
import {
  Navigation,
  NavigationBehaviorOptions,
  NavigationExtras,
  Router,
} from '@angular/router';
import {deepClone, Logger, ResponsiveService} from 'common';
import {first} from 'rxjs/operators';
import {environment} from '~environments/environment';

import {GroupService} from '../../games/groups/data/group.service';
import {TlContext} from '../theme/tl-context.enum';

@Injectable({providedIn: 'root'})
export class TlRouterService {
  constructor(
    private groupService: GroupService,
    private responsiveService: ResponsiveService,
    protected logger: Logger,
    readonly router: Router,
  ) {}

  navigate(fragments: Array<any>, extras?: NavigationExtras): Promise<boolean> {
    let calculatedFragments = fragments;

    if (!extras?.relativeTo) {
      calculatedFragments = this.calculateContextFragments(fragments);
    }

    return this.router.navigate(calculatedFragments, extras);
  }

  navigateByUrl(url: string, extras?: NavigationBehaviorOptions): Promise<boolean> {
    return this.router.navigateByUrl(this.calculateContextPath(url), extras);
  }

  calculateContextPath(url: string): string {
    let path = this.calculateContextFragments(url.split('/')).join('/');

    if (!path.startsWith('/') && !path.startsWith('../')) {
      path = '/' + path;
    }

    return path;
  }

  calculateContextFragments(fragments: Array<string>): Array<string> {
    const originalFragments = deepClone(fragments);
    if (fragments.length > 1 && fragments[0] === '') {
      fragments.shift();
    }

    if (fragments[0].startsWith('../')) {
      return fragments;
    } else if (fragments[0].startsWith('/')) {
      fragments[0] = fragments[0].slice(1);
    }

    let basePath = [];

    if (fragments[0] === 'm') {
      basePath.push('m');
      fragments.shift();
    } else if (fragments[0].startsWith('m/')) {
      basePath.push('m');
      fragments[0] = fragments[0].slice(2);
    } else if (this.responsiveService.isMobile()) {
      basePath.push('m');
    }

    const context = this.getContext();

    if (context === TlContext.GROUPS) {
      const routeMap = environment.locale.routes;
      this.groupService
        .getData()
        .pipe(first())
        .subscribe(group => {
          basePath.push(routeMap.groups.base);
          basePath.push(group.id);
        });
    }

    if (basePath.length > 0) {
      fragments = fragments.reduce((prev, curr) => {
        if (!curr) {
          this.logger.error(
            'WEB-2611: Fragments are empty on reduce',
            new Error().stack,
            {
              originalFragments: originalFragments,
              basePath: basePath,
              prev: prev,
              curr: curr,
              fragments: fragments,
            },
          );
          this.router.navigate(['/']);
        }
        return prev.concat(
          curr
            .toString()
            .split('/')
            .filter(fragment => !!fragment),
        );
      }, []);

      // check if remove basepath from fragments
      const basePathToCheck = basePath[0] === 'm' ? basePath.slice(1) : basePath;
      for (let i = 0; i < basePathToCheck.length; i++) {
        if (!fragments || fragments.length === 0) {
          const stackTrace = new Error().stack;
          this.logger.error('WEB-2611: Fragments are empty', stackTrace, {
            originalFragments,
            basePathToCheck,
            basePath,
          });
          fragments = [];
        }
        if (
          fragments &&
          fragments.length &&
          fragments[0].toString() === basePathToCheck[i].toString()
        ) {
          fragments.shift();
        } else {
          break;
        }
      }
    } else if (fragments.length === 1) {
      fragments[0] = `/${fragments[0]}`;
    }

    return [...basePath, ...fragments];
  }

  getContext(): TlContext {
    let context: TlContext;
    let route = this.router.routerState.snapshot.root.firstChild;
    context = route.data?.context;
    while (route.firstChild && !context) {
      route = route.firstChild;
      context = route.data?.context;
    }
    return context;
  }

  getCurrentNavigation(): Navigation {
    return this.router.getCurrentNavigation();
  }
}
