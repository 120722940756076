import {Serializable} from 'common';

class PaymentMethodConfigInternal {
  brandingImageUrl: string;

  allowLoad: boolean;

  allowWithdraw: boolean;

  allowDisplayLoad: boolean;

  allowDisplayWithdraw: boolean;

  allowCreate: boolean;

  fixedFee: number;

  variableFee: number;

  defaultLoad: number;

  popularLoads: Array<number>;

  mostPopularLoad: number;

  /**
   * Set the min amount that can be loaded
   */
  min: number;

  /**
   * Set the max amount that can be loaded
   */
  max: number;

  /**
   * Set the increments when the user wants to raise or lower the amount
   */
  multiple: number;

  /**
   * Set if the payment method should allow automatic reload
   */
  autocredit: boolean;
}

export class PaymentMethodConfig extends Serializable(PaymentMethodConfigInternal) {
  static createFromBackend(obj: any): PaymentMethodConfig {
    let paymentMethodConfig: PaymentMethodConfig = new PaymentMethodConfig();
    paymentMethodConfig.allowLoad = obj.allowLoad;
    paymentMethodConfig.allowWithdraw = obj.allowWithdraw;
    paymentMethodConfig.allowDisplayLoad = obj.allowDisplayLoad;
    paymentMethodConfig.allowDisplayWithdraw = obj.allowDisplayWithdraw;
    paymentMethodConfig.allowCreate = obj.allowCreate;
    paymentMethodConfig.brandingImageUrl = obj.brandingImageUrl;

    paymentMethodConfig.fixedFee = obj.fixedFee;
    paymentMethodConfig.variableFee = obj.variableFee;
    paymentMethodConfig.defaultLoad = obj.defaultLoad;
    paymentMethodConfig.mostPopularLoad = !!obj.popularLoads
      ? obj.popularLoads[0]
      : null;
    paymentMethodConfig.popularLoads = !!obj.popularLoads
      ? [...obj.popularLoads].sort((a, b) => a - b)
      : [];
    return paymentMethodConfig;
  }
}
