import {Serializable, SerializableDisableWarning} from 'common';

class SubscribableDatesInternal {
  @SerializableDisableWarning()
  day: string;

  @SerializableDisableWarning()
  hour: string;

  id: string;

  name: string;
}

export class SubscribableDates extends Serializable(SubscribableDatesInternal) {
  static createFromBackend(obj: any): SubscribableDates {
    let sd = new SubscribableDates();
    sd.day = obj?.day;
    sd.hour = obj?.hour;
    sd.id = obj?.alias;
    sd.name = obj?.aliasToShow;

    return sd;
  }

  toBackend() {
    return {
      day: this.day,
      hour: this.hour,
      alias: this.id,
    };
  }
}
