import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {LocalStorage, LocaleService, Logger, ENVIRONMENT} from 'common';

import {BackendAdapterDao} from './backend-adapter.dao';
import {BackendDao} from './backend.dao';

// TODO · ADAPTER · remove
function backendDaoFactory(
  http: HttpClient,
  localStorage: LocalStorage,
  locale: LocaleService,
  logger: Logger,
  environment: Record<string, any>,
) {
  if (environment.locale.locale === 'es-ES') {
    return new BackendAdapterDao(http, localStorage, locale, logger, environment);
  } else {
    return new BackendDao(http, localStorage, locale, logger, environment);
  }
}

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    // TODO · ADAPTER · remove
    {
      provide: BackendDao,
      useFactory: backendDaoFactory,
      deps: [HttpClient, LocalStorage, LocaleService, Logger, ENVIRONMENT],
    },
  ],
})
export class BackendModule {}
