import {NgModule} from '@angular/core';
import {environment} from '~environments/environment';

import {GeolocationService} from './geolocation.service';
import {GeolocationServiceFake} from './geolocation.service.fake';
import {GeolocationUsService} from './geolocation-us.service';
import {StatesDao} from '../states/data/states.dao';
import {LocalStorage, Logger, ResponsiveService} from 'common';

@NgModule({
  providers: [
    {
      provide: GeolocationService,
      useClass: GeolocationProvidersModule.getGeolocationService(),
      deps: GeolocationProvidersModule.getDeps(),
    },
  ],
})
export class GeolocationProvidersModule {
  private static getGeolocationService() {
    if (environment.skipLocation) {
      return GeolocationServiceFake;
    } else if (environment.id === 'us') {
      return GeolocationUsService;
    } else {
      return GeolocationService;
    }
  }

  private static getDeps() {
    if (environment.id === 'us') {
      return [LocalStorage, Logger, 'window', StatesDao, ResponsiveService];
    }
    return [LocalStorage, Logger, 'window'];
  }
}
