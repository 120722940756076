import {
  Directive,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {ResponsiveService} from '../responsive.service';

@Directive({
  selector: '[tlIfDesktop]',
})
export class IfDesktopDirective implements OnInit, OnChanges {
  // For avoid warning *tlIfDesktop requires value
  @Input()
  tlIfDesktop = false;

  @Input()
  tlIfDesktopAnd?: boolean;

  @Input()
  tlIfDesktopElse?: TemplateRef<any>;

  constructor(
    private responsiveService: ResponsiveService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.renderView();
  }

  ngOnChanges(): void {
    this.renderView();
  }

  private renderView() {
    this.viewContainerRef.clear();

    if (
      this.responsiveService.isDesktop() &&
      (this.tlIfDesktopAnd === undefined || this.tlIfDesktopAnd)
    ) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (this.tlIfDesktopElse) {
      this.viewContainerRef.createEmbeddedView(this.tlIfDesktopElse);
    }
  }
}
