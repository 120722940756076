import {DOCUMENT, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import localeMx from '@angular/common/locales/es-MX';
import localePt from '@angular/common/locales/pt-PT';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injector,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import {
  ActivatedRouteService,
  APP_VERSION,
  AppHttpModule,
  DeviceService,
  deviceServiceFactory,
  ENVIRONMENT,
  FingerprintStrategy,
  GoogleModule,
  AppleModule,
  I18nModule,
  LocalStorageModule,
  LoggerModule,
  MetaRouterService,
  PageControllerModule,
  PopstateService,
  PreviousRouteService,
  PRODUCTION,
  RouterNavigationCheckerService,
  SessionStorageModule,
} from 'common';
import {environment} from '~environments/environment';

import {AppRoutingModule} from './app-routing.module';
import {AppUriMappingModule} from './app-uri-mapping-module';
import {AppViewService} from './app-view.service';
import {AppComponent} from './app.component';
import {BackendModule} from './backend/backend.module';
import {BoothsUriMappingModule} from './booth/booths-uri-mapping.module';
import {BrowserSupportModule} from './browser-support/browser-support.module';
import {AlertsProvidersModule} from './common/alerts/alerts-providers.module';
import {TuloteroDeviceModule} from './common/device/tulotero-device.module';
import {FacebookProvidersModule} from './common/facebook/facebook-providers.module';
import {GoogleProvidersModule} from './common/google/google-providers.module';
import {HttpProvidersModule} from './common/http/http-providers.module';
import {I18nProvidersModule} from './common/i18n/i18n-providers.module';
import {TuLoteroLocalStorageModule} from './common/local-storage/tu-lotero-local-storage.module';
import {LoggerProvidersModule} from './common/logger/logger-providers.module';
import {BankAccountProvidersModule} from './common/money/bank-account/bank-account-providers.module';
import {EventManagerProvidersModule} from './common/platform-browser/event-manager-providers.module';
import {RouterProvidersModule} from './common/router/router-providers.module';
import {UriProvidersModule} from './common/uri/uri-providers.module';
import {CodeVerificationDialogModule} from './common/verification/dialog/code-verification-dialog.module';
import {VerificationModule} from './common/verification/verification.module';
import {ContactsModelModule} from './contacts/data/contacts-model.module';
import {ServerErrorHandler} from './error/server-error-handler';
import {ExclusionModule} from './exclusion/exclusion.module';
import {NewCompanyClubPreviousInfoDialogModule} from './games/clubs/new/dialogs/new-company-club-previous-info-dialog/new-company-club-previous-info-dialog.module';
import {NewCompanyClubSuccessDialogModule} from './games/clubs/new/dialogs/new-company-club-success-dialog/new-company-club-success-dialog.module';
import {GamesModelModule} from './games/data/games-model.module';
import {LotteryModelModule} from './games/lottery/data/lottery-model.module';
import {MatchesModelModule} from './games/matches/data/matches-model.module';
import {VerifyPhoneDialogModule} from './games/play-dialogs/verify-phone-dialog/verify-phone-dialog.module';
import {PlayModelModule} from './games/play/model/play-model.module';
import {ResultsModelModule} from './games/results/data/results-model.module';
import {TicketModelModule} from './games/tickets/data/ticket-model.module';
import {TicketUriMappingModule} from './games/tickets/ticket-uri-mapping.module';
import {GamesUriMappingModule} from './games/uri/games-uri-mapping.module';
import {GeolocationProvidersModule} from './geolocation/geolocation-providers.module';
import {GetInTouchUriMappingModule} from './get-in-touch/get-in-touch-uri-mapping.module';
import {KycKoDialogModule} from './kyc/dialogs/kyc-ko-dialog/kyc-ko-dialog.module';
import {KycOkDialogModule} from './kyc/dialogs/kyc-ok-dialog/kyc-ok-dialog.module';
import {MaintenanceModule} from './maintenance/maintenance.module';
import {MoneyUriMappingModule} from './money/money-uri-mapping.module';
import {PrebootModule} from './preboot/preboot.module';
import {PreferencesUriMappingModule} from './preferences/preferences-uri-mapping.module';
import {ShipmentConfirmDialogModule} from './shipment-dialogs/confirm-dialog/shipment-confirm-dialog.module';
import {TicketShippingModelModule} from './shipment/model/ticket-shipping-model.module';
import {SponsorUriMappingModule} from './sponsor/sponsor-uri-mapping.module';
import {TuloteroSlidesUriMappingModule} from './tulotero/tulotero-slides-uri-mapping.module';
import {UserModelModule} from './user/auth/user-model.module';
import {UserUriMappingModule} from './user/user-uri-mapping.module';
import {TuLoteroLocalStorageService} from './common/local-storage/tu-lotero-local-storage.service';
import {LocalStorageService} from './common/local-storage/local-storage.service';
import {VersionMismatchModule} from './version-mismatch/version-mismatch.module';
import {TicketsCanceledModule} from './games/tickets/tickets-canceled/tickets-canceled.module';
import {SubscriptionsNotExecutedModule} from './games/tickets/subscriptions-not-executed/subscriptions-not-executed.module';
import {getDynamicProviders} from './app-module-dinamic-providers';
import {GroupNotMemberInterceptor} from './games/groups/shared/group-not-member.interceptor';
import {AppleProvidersModule} from './common/apple/apple-providers.module';
import {GroupUriMappingModule} from './games/groups/main/mobile/group/group-uri-mapping.module';
import {FraudErrorInterceptor} from './user/auth/fraud-error.interceptor';
import {TermsUriMappingModule} from './legal/terms-uri-mapping-module';
import {KycCompleteUriMappingModule} from './kyc/kyc-verification-mapping.module';
import {KycRequiredPrizeDialogModule} from './kyc/dialogs/kyc-required-prize-dialog/kyc-required-prize-dialog.module';
import {RequestGreatPrizeUriMappingModule} from './request-great-prize/request-great-prize-uri-mapping.module';
import {PushPromptModule} from './notifications/prompt/push-prompt.module';
import {LocalUrisInterceptor} from './common/uri/local-uris.interceptor';

// TODO - MULTILENGUAGE
// Register only language in languages.json
registerLocaleData(localeEs, 'es-ES');
registerLocaleData(localeMx, 'es-MX');
registerLocaleData(localePt, 'pt-PT');

export function _document(): any {
  return document;
}

export function _window(): any {
  return window;
}

const COMMON_MODULES = [
  AlertsProvidersModule,
  BankAccountProvidersModule,
  FacebookProvidersModule,
  GeolocationProvidersModule,
  GoogleProvidersModule,
  AppleProvidersModule,
  I18nProvidersModule,
  HttpProvidersModule,
  LoggerProvidersModule,
  EventManagerProvidersModule,
  RouterProvidersModule,
  UriProvidersModule,
];

const POPUP_MODULES = [
  CodeVerificationDialogModule,
  KycKoDialogModule,
  KycOkDialogModule,
  KycRequiredPrizeDialogModule,
  NewCompanyClubPreviousInfoDialogModule,
  NewCompanyClubSuccessDialogModule,
  ShipmentConfirmDialogModule,
  VerifyPhoneDialogModule,
];

const URI_MAPPING_MODULES = [
  AppUriMappingModule,
  BoothsUriMappingModule,
  GamesUriMappingModule,
  GetInTouchUriMappingModule,
  MoneyUriMappingModule,
  PreferencesUriMappingModule,
  SponsorUriMappingModule,
  TicketUriMappingModule,
  TuloteroSlidesUriMappingModule,
  UserUriMappingModule,
  GroupUriMappingModule,
  TermsUriMappingModule,
  KycCompleteUriMappingModule,
  RequestGreatPrizeUriMappingModule,
];

@NgModule({
  imports: [
    AppHttpModule.withInterceptors(),
    BackendModule,
    BrowserModule,
    BrowserAnimationsModule,
    BrowserSupportModule,
    ...COMMON_MODULES,
    TuloteroDeviceModule.withInterceptors(),
    ContactsModelModule,
    GamesModelModule,
    GoogleModule.forRoot(environment.mockContacts, environment.useGoogleSDK),
    AppleModule,
    I18nModule,
    LocalStorageModule,
    SessionStorageModule,
    LoggerModule,
    LotteryModelModule,
    MaintenanceModule,
    MatchesModelModule,
    PushPromptModule,
    PageControllerModule,
    ...POPUP_MODULES,
    PlayModelModule,
    PrebootModule,
    ReactiveFormsModule,
    ResultsModelModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.serviceWorkerEnabled,
    }),
    TicketModelModule,
    TicketShippingModelModule,
    TuLoteroLocalStorageModule,
    ...URI_MAPPING_MODULES,
    UserModelModule.withInterceptors(),
    VerificationModule.withInterceptors(),
    ExclusionModule,
    HttpClientModule, // This should be imported under all modules
    AppRoutingModule, // This should be after child routes
    TicketsCanceledModule,
    SubscriptionsNotExecutedModule,
    VersionMismatchModule,
  ],
  providers: [
    {provide: APP_VERSION, useValue: environment.appVersion},
    {provide: DOCUMENT, useFactory: _document, deps: []},
    {provide: ENVIRONMENT, useValue: environment},
    {provide: ErrorHandler, useClass: ServerErrorHandler},
    // TODO - MULTILENGUAGE
    // Remove LOCALE_ID
    {provide: LOCALE_ID, useValue: environment.locale.locale},
    {provide: PRODUCTION, useValue: environment.production},
    {provide: 'window', useFactory: _window, deps: []},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      // Services needed to be instantiated during app initialization
      deps: [
        ActivatedRouteService,
        AppViewService,
        MetaRouterService,
        PopstateService,
        PreviousRouteService,
        RouterNavigationCheckerService,
      ],
      multi: true,
    },
    {
      provide: TuLoteroLocalStorageService,
      useClass: environment.useIndexedDB
        ? TuLoteroLocalStorageService
        : LocalStorageService,
    },
    ...getDynamicProviders(),
    ...(environment.groups.enable
      ? [
          {
            provide: HTTP_INTERCEPTORS,
            useClass: GroupNotMemberInterceptor,
            multi: true,
          },
        ]
      : []),
    ...(environment.enableLocalProxy
      ? [
          {
            provide: HTTP_INTERCEPTORS,
            useClass: LocalUrisInterceptor,
            multi: true,
          },
        ]
      : []),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FraudErrorInterceptor,
      multi: true,
    },
    {
      provide: DeviceService,
      useFactory: deviceServiceFactory,
      deps: [FingerprintStrategy, Injector],
    },
  ],
  declarations: [AppComponent],
  exports: [AppComponent],
})
export class AppModule {}
