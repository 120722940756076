import {Component, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {environment} from '~environments/environment';

import {PlatformGuard} from './common/responsive/platform-guard';
import {SwRedirectGuard} from './sw-redirect-guard';
import {RedirectLotteryNumberGuard} from './redirect-lottery-number/redirect-lottery-number.guard';

const routeMap = environment.locale.routes;

/**
 * Stub for the SW redirect route, it's here to avoid importing from
 * common/testing, it's a weird behavior imo.
 */

/* eslint-disable prefer-none-view-encapsulation */
@Component({selector: 'tl-noop', template: ``})
export class NoopComponent {}

const routes: Routes = [
  {
    path: 'sw',
    canActivate: [SwRedirectGuard],
    component: NoopComponent,
  },
  {
    path: 'm',
    loadChildren: () => import('./app-mobile.module').then(m => m.AppMobileModule),
    canActivate: [
      PlatformGuard,
      ...(environment.features.games.lottery.redirectToLotteryNumber
        ? [RedirectLotteryNumberGuard]
        : []),
    ],
    canActivateChild: [PlatformGuard],
  },
  {
    path: routeMap.companies,
    loadChildren: () =>
      import(
        './games/clubs/register-companies/desktop/company-club-register-layout.module'
      ).then(m => m.CompanyClubRegisterLayoutModule),
    canActivate: [PlatformGuard],
    canActivateChild: [PlatformGuard],
  },
  {
    path: '',
    loadChildren: () => import('./app-desktop.module').then(m => m.AppDesktopModule),
    canActivate: [
      PlatformGuard,
      ...(environment.features.games.lottery.redirectToLotteryNumber
        ? [RedirectLotteryNumberGuard]
        : []),
    ],
    canActivateChild: [PlatformGuard],
  },
  {path: '**', redirectTo: routeMap.notFound},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
  providers: [
    PlatformGuard,
    SwRedirectGuard,
    ...(environment.features.games.lottery.redirectToLotteryNumber
      ? [RedirectLotteryNumberGuard]
      : []),
  ],
})
export class AppRoutingModule {}
