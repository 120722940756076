import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {Logger} from '../logger/logger';
import {AbstractObservableDataService} from '../model/abstract-observable-data.service';
import {compile} from '../util/strings';

import {LocaleService} from './locale.service';
import {ENVIRONMENT} from '../environment/environment-token';
import {Language} from './language';
import {setDateLocaleByLocaleId} from '../date/date-fns-wrapper';
import {EXCLUDED_KEYS_REPLACE} from './excluded-key-replace';

@Injectable({providedIn: 'root'})
export class TranslationsService extends AbstractObservableDataService<any> {
  constructor(
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
    private http: HttpClient,
    private localeService: LocaleService,
    private logger: Logger,
  ) {
    super();
  }

  public getLanguagesByEnvironment(): Observable<Array<Language>> {
    //   "endpoints": {
    //     "ES":
    //     [
    //       "es_ES",
    //       "en_US",
    //       "es_MX"
    //     ],
    //     ...
    //  },
    //  "languageNames": {
    //    "es_ES": "Español (España)",
    //    "pt_PT": "Português (Portugal)",
    //    "en_US": "English (United States)",
    //    "es_MX": "Español (México)"
    //  }
    return this.http.get(`assets/data/translations/languages.json`).pipe(
      map((data: any) => {
        const environmentCode = this.environment.id.toUpperCase();
        const locales = data.endpoints[environmentCode];
        return locales.map((code: string) => ({
          code: code.replace('_', '-'),
          name: data.languageNames[code],
        }));
      }),
    );
  }

  load(): Observable<unknown> {
    return this.loadFile(this.environment.id, this.localeService.getLocale()).pipe(
      tap(translations => {
        setDateLocaleByLocaleId(this.localeService.getLocale());
        this._data.next(translations);
      }),
    );
  }

  public getTranslation(key: string) {
    return this.getKeyTranslation(key).pipe(
      tap(sentence => this.checkTranslationData(sentence, key)),
    );
  }

  getCompiledTranslation(
    key: string,
    data: {[key: string]: any},
  ): Observable<string> {
    return this.getKeyTranslation(key).pipe(
      tap(sentence => this.checkTranslationData(sentence, key, data)),
      map(sentence => {
        if (typeof sentence !== 'string') {
          return null;
        }

        return compile(sentence, data);
      }),
    );
  }

  protected getKeyTranslation(key: string): Observable<string> {
    return this._data.asObservable().pipe(
      map(data => {
        if (key == null) {
          return null;
        }

        let chunks = key.split('.');
        return chunks.reduce(
          (value: any, chunk: string) =>
            value && value.hasOwnProperty(chunk) ? value[chunk] : key,
          data,
        );
      }),
      tap(value => {
        if (value === key) {
          this.logger.error(`ERROR TRANSLATE: ${key}`, new Error().stack);
        } else if (!value && value !== '') {
          this.logger.warn(`ERROR TRANSLATE: undefined translation`, {
            key: key,
            value: value,
          });
        }
      }),
      map((value: string) => {
        return EXCLUDED_KEYS_REPLACE.includes(key)
          ? value
          : value?.replaceAll('\\n', '<br />');
      }),
    );
  }

  private checkTranslationData(
    sentence: string,
    key: string,
    data?: {[key: string]: any},
  ): boolean {
    if (!sentence) {
      return false;
    }

    const dataKeys = data ? Object.keys(data).sort() : [];
    const senteceKeys = this.getVariables(sentence).sort();

    // Verificar si tienen la misma longitud
    if (dataKeys.length !== senteceKeys.length) {
      this.logger.error(
        `ERROR TRANSLATE: ${key}. not declared sames variables in booth sencences \n`,
        undefined,
        {
          key: key,
          sentence: sentence,
          dataKeys: dataKeys,
          senteceKeys: senteceKeys,
        },
      );
      return false;
    }

    let diffs = [];
    for (let i = 0; i < dataKeys.length; i++) {
      if (senteceKeys[i] !== dataKeys[i]) {
        diffs.push(dataKeys[i]);
      }
    }

    if (diffs?.length) {
      this.logger.error(
        `ERROR TRANSLATE: ${key}. Translate vars:
        ${JSON.stringify(diffs)} are not defined in sentence\n`,
        undefined,
        {
          key: key,
          sentence: sentence,
          dataKeys: dataKeys,
          senteceKeys: senteceKeys,
        },
      );
      return false;
    }

    return true;
  }

  /** @returns array of variables declared in sentence */
  private getVariables(sentence: string): string[] {
    const regex = /\{([^}]+)\}/g;

    const variables = sentence.match(regex) || [];

    return (
      variables
        .map(v => v.replace(/[{}]/g, ''))
        // Only distinc
        .filter((value, index, array) => array.indexOf(value) === index)
    );
  }

  private loadFile(environmentCode: string, locale: string): Observable<unknown> {
    const localeFixed = locale.replace('-', '_');
    return this.http.get(
      `assets/data/translations/${environmentCode.toUpperCase()}/${localeFixed}.json`,
    );
  }
}
