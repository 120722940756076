export class TuLoteroServerError {
  statusCode: number;

  status: string;

  message?: string;

  messageKey?: string;

  url?: string;

  subtitle?: string;

  description?: string;

  isUserNotifyMeStateAvailable?: boolean;

  code?: string;

  constructor(
    statusCode: number,
    status: string,
    message: string,
    messageIsKey: boolean,
    url?: string,
    subtitle?: string,
    description?: string,
    isUserNotifyMeStateAvailable?: boolean,
    code?: string,
  ) {
    this.statusCode = statusCode;
    this.status = status;
    if (!messageIsKey) {
      this.message = message || '';
    } else {
      this.messageKey = message;
    }
    this.subtitle = subtitle;
    this.description = description;
    this.url = url;
    this.isUserNotifyMeStateAvailable = isUserNotifyMeStateAvailable;
    this.code = code;
  }
}
