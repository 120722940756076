<div class="form-group tl-input-password__group" [ngClass]="{xs: xs}">
  <label class="sr-only" [for]="id"></label>
  <div class="input-group">
    <input
      #nativeInput
      class="form-control"
      [ngClass]="{'input-xs': xs}"
      [type]="inputType"
      [attr.maxlength]="maxlength"
      autocomplete="new-password"
      [tlTriggerOnKeyboardOpen]="65"
      [id]="id"
      [tabindex]="ngTabindex"
      (input)="onInput($event)"
      (focus)="markTouched()"
      (blur)="onBlur()"
      [placeholder]="placeholder" />
    <div [ngClass]="{xs: xs}" class="input-group-append">
      <div class="input-group-text">
        <ng-container *ngIf="showPassword; else hidePassword">
          <i
            class="icon-eye-off tl-input-password__eye"
            [ngClass]="{'tl-input-password__eye--white': whiteEye}"
            (click)="tooglePassword()"></i>
        </ng-container>
        <ng-template #hidePassword>
          <i
            class="icon-eye tl-input-password__eye"
            [ngClass]="{'tl-input-password__eye--white': whiteEye}"
            (click)="tooglePassword()"></i>
        </ng-template>
      </div>
    </div>
  </div>
</div>
