import {Inject, Injectable} from '@angular/core';
import {ENVIRONMENT, LocalStorage} from 'common';
import {Observable, of} from 'rxjs';

@Injectable()
export class VersionMismatchService {
  constructor(
    @Inject(ENVIRONMENT) private environment: Record<string, any>,
    private localStorage: LocalStorage,
  ) {}

  // version == current version not updatedVersion
  runHooks(version: number): Observable<void> {
    if (1092 <= version && version <= 1097) {
      this.localStorage.removeItem(this.environment.localStorageKeys.kycLaunched);
      this.localStorage.removeItem(this.environment.localStorageKeys.counterShares);
      this.localStorage.removeItem(this.environment.localStorageKeys.boothsLastDate);
    }
    if (version > 1155 && version <= 1156) {
      this.clearUnusedKeys();
    }
    if (version >= 1188 && version < 1189) {
      // Needed for get new user filters again. Old filters are corrupted.
      this.removeKey(this.environment.localStorageKeys.ticketsGameFilters);
    }

    return of(void 0);
  }

  private clearUnusedKeys() {
    const localStorageKeys: Array<string> = Object.keys(
      this.environment.localStorageKeys,
    );
    const localStorageKeysToDelete: Array<string> = [];
    for (let i = 0; i < this.localStorage.length; i++) {
      const key = this.localStorage.key(i);
      if (!localStorageKeys.includes(key)) {
        localStorageKeysToDelete.push(key);
      }
    }
    localStorageKeysToDelete.forEach(key => this.localStorage.removeItem(key));
  }

  private removeKey(key: string) {
    this.localStorage.removeItem(key);
  }
}
