import {Inject, Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {from, Observable, of} from 'rxjs';
import {Logger, ResponsiveService, ROUTES} from 'common';
import {UpcomingGamesService} from '../games/upcoming/data/upcoming-games.service';
import {catchError, mapTo, switchMap} from 'rxjs/operators';

/**
 * Guard to redirect to the lottery number page if the query params
 * sorteoId and numberFilter are present
 * and raffle is included on upcomingGames list.
 */
@Injectable()
export class RedirectLotteryNumberGuard implements CanActivate {
  constructor(
    private logger: Logger,
    private router: Router,
    private upcomingGamesService: UpcomingGamesService,
    @Inject(ROUTES) private routes: Record<string, any>,
    private responsiveService: ResponsiveService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const url = new URLSearchParams(state.url.split('?')[1]);
    const sorteoId: number = +url.get('sorteoId');
    const numberFilter: string = url.get('numberFilter');
    if (sorteoId && numberFilter) {
      // redirect to the lottery number page
      return this.upcomingGamesService.getGameByRaffleId(sorteoId).pipe(
        catchError(() => {
          return of(null);
        }),
        switchMap(game => {
          if (game) {
            const isMobile: boolean = this.responsiveService.isMobile();
            const gameUrl: string = this.routes.play.raffle
              .replace(':id', game.gameId)
              .replace(':raffleId', sorteoId);
            return from(
              this.router.navigate([isMobile ? '/m' : '/', ...gameUrl.split('/')], {
                queryParams: {[this.routes.play.lotteryNumberParam]: numberFilter},
                state: {shouldRedirect: true},
              }),
            ).pipe(mapTo(true));
          } else {
            this.logger.error('Tried redirecting to a non-existent raffle', null, {
              sorteoId,
              numberFilter,
            });
            return from(this.router.navigate(['/'])).pipe(mapTo(false));
          }
        }),
      );
    }
    return true;
  }
}
