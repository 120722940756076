import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {
  inject,
  Injectable,
  InjectionToken,
  INJECTOR,
  LOCALE_ID,
} from '@angular/core';
import {
  AbstractObservableDataService,
  APP_VERSION,
  consoleFactory,
  Logger,
  loggerFactory,
  LOGSTASH_ENDPOINT,
  PRODUCTION,
  ServiceInstanciator,
} from 'common';
import {BehaviorSubject, Observable, Observer} from 'rxjs';
import {first, map, switchMap, tap} from 'rxjs/operators';

import {ErrorService} from '../../../error/error.service';

import {Group} from './group';
import {GroupHistoryService} from './group-history.service';
import {GroupDao} from './group.dao';
import {GroupService} from './group.service';
import {MoneyActivityGroupService} from './money-activity-group.service';

export const GROUP_INFO_SERVICE_INSTANCIATOR =
  new InjectionToken<ServiceInstanciator>('GroupInfoServiceInstanciator', {
    providedIn: 'root',
    factory: () =>
      new GroupInfoServiceInternal(
        inject(ErrorService),
        inject(GroupDao),
        inject(GroupHistoryService),
        inject(GroupService),
        loggerFactory(
          inject(APP_VERSION),
          consoleFactory(),
          inject(HttpClient),
          INJECTOR,
          inject(PRODUCTION),
          inject(LOGSTASH_ENDPOINT),
          inject(LOCALE_ID),
        ),
        inject(MoneyActivityGroupService),
      ),
  });

@Injectable({providedIn: 'root', useExisting: GROUP_INFO_SERVICE_INSTANCIATOR})
export abstract class GroupInfoService extends AbstractObservableDataService<Group> {
  // When groups info is updated on privateInfo, if listenGroupChanges is true, launch update().
  listenGroupChanges: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  abstract update(): Observable<Group>;
}

export class GroupInfoServiceInternal
  extends GroupInfoService
  implements ServiceInstanciator
{
  constructor(
    private errorsService: ErrorService,
    private groupDao: GroupDao,
    private groupHistoryService: GroupHistoryService,
    private groupService: GroupService,
    private logger: Logger,
    private moneyActivityGroupService: MoneyActivityGroupService,
  ) {
    super();
  }

  create(groupId: number): Observable<Group> {
    return this.groupDao.get(groupId).pipe(
      tap({
        next: (group: Group) => {
          this.setData(group);
          this.listenGroupChanges.next(true);
        },
        error: (e: HttpErrorResponse) => {
          this.errorsService.processErrorGlobalContext(e, {
            key: 'groups.edit.failure',
          });
        },
      }),
    );
  }

  destroy(): void {
    super.setData(null);
    this.listenGroupChanges.next(false);
  }

  update(): Observable<Group> {
    return new Observable((observer: Observer<Group>) => {
      this._data
        .pipe(
          first(),
          switchMap(group =>
            this.groupDao
              .get(group.id, group.lastUpdate)
              .pipe(map(groupDiff => [group, groupDiff])),
          ),
        )
        .subscribe({
          next: ([group, groupDiff]: [Group, Group]) => {
            try {
              group.merge(groupDiff);
              group.reduce();
              this.fillServices(group, groupDiff);
              super.setData(group);
              observer.next(group);
              observer.complete();
            } catch (e) {
              this.logger.error(e.message, e.stack);
              this.create(group.id).subscribe((g: Group) => {
                observer.next(g);
                observer.complete();
              });
            }
          },
          error: e => {
            this.errorsService.processErrorGlobalContext(e, {
              key: 'groups.edit.failure',
            });
            observer.error(e);
            observer.complete();
          },
        });
    });
  }

  setData(group: Group): void {
    super.setData(group);
    this.fillServices(group, group);
  }

  private fillServices(group: Group, groupDiff: Group): void {
    this.groupService.setData(group);

    if (groupDiff.history && groupDiff.history.length > 0) {
      this.groupHistoryService.setInitialData(
        groupDiff.history.slice().reverse(),
        groupDiff,
      );
    }

    if (
      (groupDiff.moneyActivities && groupDiff.moneyActivities.length > 0) ||
      group === groupDiff
    ) {
      this.moneyActivityGroupService.setData(group.moneyActivities);
    }

    if (groupDiff.totalMoneyActivities) {
      this.moneyActivityGroupService.setMaxPagination(
        groupDiff.totalMoneyActivities,
      );
    }
  }
}
