import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {
  I18nModule,
  ResponsiveUtilsModule,
  RoundIconButtonModule,
  SemiModalModule,
  UtilModule,
} from 'common';

import {ShareClubCodeComponent} from './share-club-code.component';

@NgModule({
  imports: [
    CommonModule,
    UtilModule,
    I18nModule,
    SemiModalModule,
    ResponsiveUtilsModule,
    RoundIconButtonModule,
  ],
  exports: [ShareClubCodeComponent],
  declarations: [ShareClubCodeComponent],
})
export class ShareClubCodeModule {}
