import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {
  I18nModule,
  ModalDialogModule,
  TranslatableTextModule,
  UtilModule,
} from 'common';
import {PushPromptComponent} from './push-prompt.component';

@NgModule({
  imports: [
    I18nModule,
    ModalDialogModule,
    CommonModule,
    UtilModule,
    TranslatableTextModule,
  ],
  exports: [PushPromptComponent],
  declarations: [PushPromptComponent],
})
export class PushPromptModule {}
