export enum GameId {
  LOTERIA_NACIONAL = 'LOTERIA_NACIONAL',
  BONOLOTO = 'BONOLOTO',
  EURODREAMS = 'EURODREAMS',
  QUINIELA = 'QUINIELA',
  CRUZ_ROJA = 'CRUZ_ROJA',
  EUROMILLONES = 'EUROMILLONES',
  PRIMITIVA = 'PRIMITIVA',
  GORDO_PRIMITIVA = 'GORDO_PRIMITIVA',
  LOTARIA_CLASSICA = 'LOTARIA_CLASSICA',
  LOTARIA_POPULAR = 'LOTARIA_POPULAR',
  TOTOBOLA = 'TOTOBOLA',
  TOTOBOLA_EXTRA = 'TOTOBOLA_EXTRA',

  // MX
  CHISPAZO = 'CHISPAZO',
  TRIS = 'TRIS',
  PROGOL = 'PROGOL',
  PROTOUCH = 'PROTOUCH_PLAY',
  PROGOL_MS = 'PROGOL_MEDIA',

  // US
  COLORADO_LOTTO_PLUS = 'COLORADO_LOTTO_PLUS',
  MINNESOTA_PICK_3 = 'MINNESOTA_PICK_3',

  NEW_YORK_TAKE_5 = 'NEW_YORK_TAKE_5',
  NEW_YORK_NUMBERS = 'NEW_YORK_NUMBERS',
  NEW_YORK_WIN_4 = 'NEW_YORK_WIN_4',
}
