import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';
import {
  AlertsService,
  DistanceUnit,
  ResponsiveService,
  ToggleCloseOthersService,
  TooltipService,
  TranslatableText,
} from 'common';

import {BoothSelectedService} from '../../booth-selected.service';
import {BoothsSearchViewService} from '../../booths-search-view.service';
import {LotteryBooth} from '../../data/lottery-booth';
import {LotteryBoothsListBaseComponent} from './lottery-booths-list-base.component';
import {LotteryBoothsGeolocationViewService} from '../../lottery-booths-geolocation-view.service';
import {LotteryBoothsLocateViewService} from '../../lottery-booths-locate-view.service';
import {LotteryBoothItemComponent} from '../booth-item/booth-item.component';
import {SessionService} from '../../../user/auth/session.service';
import {BoothViewDataService} from '../../booth-view-data.service';
import {
  Observable,
  Subscription,
  filter,
  map,
  take,
  takeUntil,
  takeWhile,
} from 'rxjs';
import {LotteryBoothService} from '../../data/lottery-booth.service';
import {EndpointService} from '../../../backend/endpoint/endpoint.service';

// eslint-disable-next-line prefer-none-view-encapsulation
@Component({
  selector: 'tl-lottery-booths-list',
  templateUrl: './lottery-booths-list.component.html',
  styleUrls: ['./lottery-booths-list.component.scss'],
  providers: [LotteryBoothsLocateViewService, ToggleCloseOthersService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('SlideFromBottom', [
      state('void', style({maxHeight: '0px'})),
      state('*', style({maxHeight: '500px'})),
      transition(':enter, :leave', [animate('0.5s ease-in-out')]),
    ]),
  ],
})
export class LotteryBoothsListComponent
  extends LotteryBoothsListBaseComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input()
  currentBoothId: string;

  @Input()
  geolocationEnabled = false;

  @Input()
  readonly = false;

  scrollSubscription: Subscription;

  distanceUnit: DistanceUnit;

  @ViewChild(LotteryBoothItemComponent, {read: ElementRef})
  boothItem: ElementRef;

  helpOpenned = false;

  get availableHeight(): number {
    // Screen height - (header + tabs + input) - banner booth fav
    return (
      this.responsiveService.getCurrentScreen().y -
      131 -
      (!!this.currentBoothId ? 0 : 25)
    );
  }

  get minBufferPx(): number {
    return this.availableHeight * 0.3;
  }

  get maxBufferPx(): number {
    return this.availableHeight * 0.8;
  }

  get itemSize(): number {
    if (this.listMode) {
      if (this.watchingGeoLocation && this.responsiveService.isMobile()) {
        return 96;
      }
      return 72;
    }
    if (this.responsiveService.isDesktop()) {
      return 96;
    } else {
      return this.watchingGeoLocation ? 98 : 78;
    }
  }

  get loadingLocation(): Observable<boolean> {
    if (this.useBoothService) {
      return this.boothViewDataService.loadingLocation;
    } else {
      return this.lotteryBoothsLocateViewService.loadingLocation;
    }
  }

  get emptyLabel(): TranslatableText {
    if (this.useBoothService) {
      return this.boothViewDataService.emptyLabel;
    }
    return {key: 'administrationOffice.adminSearchEmptyResults'};
  }

  private _viewport: CdkVirtualScrollViewport;
  @ViewChild(CdkVirtualScrollViewport)
  set viewport(viewport: CdkVirtualScrollViewport) {
    this._viewport = viewport;
    this.listenScrollPagination();
  }

  get viewport(): CdkVirtualScrollViewport {
    return this._viewport;
  }

  private readonly HEADER_HEIGHT = this.responsiveService.isDesktop()
    ? 56 + 42 + 56
    : 50 + 56;

  constructor(
    public boothGeolocationViewService: LotteryBoothsGeolocationViewService,
    protected boothSelectedService: BoothSelectedService,
    protected boothsSearchViewService: BoothsSearchViewService,
    protected cdr: ChangeDetectorRef,
    public lotteryBoothsLocateViewService: LotteryBoothsLocateViewService,
    protected tooltipService: TooltipService,
    protected responsiveService: ResponsiveService,
    protected sessionService: SessionService,
    protected alertsService: AlertsService,
    protected router: Router,
    protected lotteryBoothService: LotteryBoothService,
    protected endpointService: EndpointService,
    @Optional() protected boothViewDataService: BoothViewDataService,
  ) {
    super(
      boothsSearchViewService,
      boothSelectedService,
      sessionService,
      alertsService,
      router,
      responsiveService,
      cdr,
      lotteryBoothService,
      boothViewDataService,
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.endpointService
      .getData()
      .pipe(
        take(1),
        map(endpoint => endpoint.metricUnit),
      )
      .subscribe(unit => (this.distanceUnit = unit));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.viewport &&
      changes.hasOwnProperty('currentBoothId') &&
      this.currentBoothId &&
      this.mode === 'select'
    ) {
      const index = this.booths.findIndex(booth => booth.id === this.currentBoothId);
      const itemMargin = 5;
      const indexOffset =
        (this.boothItem.nativeElement.offsetHeight + itemMargin) * (index + 1);
      const scrollTop = this.viewport.measureScrollOffset('top');
      const viewportHeight = this.viewport.elementRef.nativeElement.offsetHeight;
      if (indexOffset < scrollTop || indexOffset > scrollTop + viewportHeight) {
        this.viewport.scrollToOffset(indexOffset - 50, 'smooth');
      }
    }
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    if (this.geolocationEnabled) {
      this.locate();
    } else {
      setTimeout(() => this.tooltipService.showVolatile('booths-geolocation'), 400);
    }
  }

  clearSearch(): void {
    this.searchInput.nativeElement.value = '';
  }

  toggleHelp(): void {
    this.helpOpenned = !this.helpOpenned;
  }

  onBoothSelection(event: MouseEvent, booth: LotteryBooth): void {
    event.stopPropagation();

    this.onBoothSelected(booth);

    if (this.mode === 'favorite' || booth?.id !== this.currentBoothId) {
      this.currentBoothId = booth ? booth.id : null;
    }

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.tooltipService.clearVolatileStorage();
  }

  toogleLocate(): void {
    if (!this.watchingGeoLocation) {
      this.locate();
    } else {
      if (this.useBoothService) {
        this.boothViewDataService.disableSortByGeolocation();
      } else {
        this.lotteryBoothsLocateViewService.disableGeolocation();
      }
      this.watchingGeoLocation = false;
    }
    this.cdr.markForCheck();
  }

  protected getPaginationSize(): number {
    return Math.round((window.innerHeight - this.HEADER_HEIGHT) / this.itemSize + 1);
  }

  protected updateViewPort(): void {
    if (this.viewport) {
      this.viewport.checkViewportSize();
      this.cdr.detectChanges();
    }
  }

  private locate(): void {
    if (this.useBoothService) {
      this.boothViewDataService.toogleSortByGeolocation();
    } else {
      this.lotteryBoothsLocateViewService
        .locate()
        .subscribe(position => this.onGeolocationUpdated(position));
    }
  }

  private onGeolocationUpdated(position: GeolocationPosition): void {
    this.watchingGeoLocation = true;
    this.tooltipService.markAsShown('booths-geolocation');
    if (this.viewport) {
      this.viewport.scrollToIndex(0);
    }
  }

  private listenScrollPagination() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
    if (this.viewport && this.useBoothService) {
      this.scrollSubscription = this.viewport
        .elementScrolled()
        .pipe(
          takeUntil(this.destroySubject),
          takeWhile(() => !this.boothViewDataService.paginationEnd),
          filter(
            () =>
              !this.loading &&
              this.viewport.measureScrollOffset('bottom') < this.itemSize / 2,
          ),
        )
        .subscribe(() => {
          this.loadMore();
        });
    }
  }
}
