import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  NgbDropdown,
  NgbDropdownConfig,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import {PlacementArray} from '@ng-bootstrap/ng-bootstrap/util/positioning';

import {ModalHelperService} from '../../modal/modal-helper.service';
import {ngModelProvider} from '../../model/ng-model-config';
import {ResponsiveService} from '../../responsive/responsive.service';
import {AbstractNgModel} from '../abstract-ngmodel';

/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */

/* eslint-disable prefer-none-view-encapsulation */
@Component({
  selector: 'tl-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [ngModelProvider(DropdownComponent), NgbDropdownConfig],
})
export class DropdownComponent
  extends AbstractNgModel<any>
  implements OnInit, OnChanges
{
  @Input()
  valueSet: Array<any | Array<Array<any>>>;

  /**
   * Array of objects that will be delegated to template item or Array of array
   * to split dropdown sections or Array of array to split dropdown sections.
   */
  values: Array<Array<any>>;

  @Input()
  value: any;

  @Input()
  model: any;

  @Input()
  buttonClassName: string;

  @Input()
  showToggle = true;

  @Input()
  contentClassName: string;
  /**
   * Default selection item string if model is null or undefined
   *
   * @example "All games" (A list to filter games)
   */
  @Input()
  emptySelectionKey: string;

  @Input()
  emptySelectionAsOption = true;

  @Input()
  placeholder;

  @Input()
  keyHeadersSet: Array<string>;

  @Input()
  appendToBody = false;

  /**
   * Maps valueSet item to model
   *
   * @example (valueSetItem) => valuesetItem.fooProp
   */
  @Input()
  valueFn: (value: any) => any;

  @Input()
  disabled = false;

  @Input()
  readonly = false;

  /**
   * Template reference to style selection item
   */
  @Input()
  itemTemplate: TemplateRef<any>;

  @Input()
  fullScreen = false;

  @Output()
  changeSelected = new EventEmitter<number | Array<number>>();

  @ViewChild(NgbDropdown)
  dropdown: NgbDropdown;

  @ViewChild('modalTemplate', {static: true})
  modalTemplate: TemplateRef<any>;

  @ViewChild('fullScreenTemplate', {static: true})
  fullScreenTemplate: TemplateRef<any>;

  currentSelected: any;

  private modalRef: NgbModalRef;

  @Input()
  set placement(placement: PlacementArray) {
    this.config.placement = placement;
  }

  get isDropdown() {
    return this.responsiveService.isDesktop();
  }

  constructor(
    private config: NgbDropdownConfig,
    private elementRef: ElementRef,
    private modalHelperService: ModalHelperService,
    private responsiveService: ResponsiveService,
  ) {
    super();
    this.config.autoClose = false;
  }

  close(): void {
    if (this.isDropdown) {
      this.dropdown.close();
    } else if (this.modalRef) {
      this.modalRef.dismiss('Close manually');
      this.modalRef = null;
    }
  }

  open(): void {
    if (this.isDropdown) {
      this.dropdown.open();
    } else {
      if (!this.fullScreen) {
        this.modalRef = this.modalHelperService.openModal(this.modalTemplate);
      } else {
        this.modalRef = this.modalHelperService.openModal(this.fullScreenTemplate, {
          modalOptions: {
            windowClass: 'fullscreen',
          },
        });
      }
    }
  }

  @HostListener('document:click', ['$event'])
  onOutsideClick(event: MouseEvent): void {
    // filter clicks on popover window
    if (this.dropdown && this.dropdown.isOpen()) {
      if (!this.elementRef.nativeElement.contains(event.target as Element)) {
        this.dropdown.close();
      }
    }
  }

  ngOnInit(): void {
    this.setValueSafe(this.model);
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.hasOwnProperty('valueSet') && this.valueSet) {
      this.values = !Array.isArray(this.valueSet[0])
        ? [this.valueSet]
        : this.valueSet;
    }

    if (
      simpleChanges.hasOwnProperty('valueSet') ||
      (simpleChanges.hasOwnProperty('emptySelectionKey') &&
        simpleChanges['emptySelectionKey'].isFirstChange())
    ) {
      this.setDefaultvalue();
    }

    if (simpleChanges.hasOwnProperty('value')) {
      this.writeValue(this.value);
    }
  }

  writeValue(obj: any): void {
    if (obj != null) {
      this.setValueSafe(obj);
    } else {
      this.setDefaultvalue();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  select(selectedValue: any): void {
    this.currentSelected = selectedValue;
    this.model = this.extractValue(selectedValue);

    if (this.isDropdown && this.dropdown) {
      this.dropdown.close();
    } else if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = null;
    }

    this.notify();
  }

  toogle(): void {
    if (this.dropdown && this.dropdown.isOpen()) {
      this.close();
    } else {
      this.open();
    }
  }

  private setDefaultvalue(): any {
    if (this.emptySelectionKey || !this.values) {
      this.currentSelected = null;
      this.model = null;
    } else {
      this.currentSelected = this.values[0][0];
      this.model = this.extractValue(this.values[0][0]);
    }
  }

  private extractValue(obj: any): any {
    return this.valueFn ? this.valueFn(obj) : obj;
  }

  private setValueSafe(obj: any): void {
    if (!this.values) {
      return;
    }
    let found;

    this.values.forEach(array => {
      if (!found) {
        found = array.find(item => obj === this.extractValue(item));
      }
    });

    if (found) {
      this.model = obj;
      this.currentSelected = found;
    } else {
      this.setDefaultvalue();
    }
  }

  private notify(): void {
    this.modelChange(this.model);

    if (this.changeSelected.observed) {
      this.changeSelected.emit(this.model);
    }
  }
}
