import {DecimalPipe} from '@angular/common';
import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DistanceUnit} from './distance-unit';

@Pipe({name: 'distance'})
export class DistancePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) protected localeId: string) {}

  transform(
    value: any,
    unit: DistanceUnit = DistanceUnit.METERS,
    displayFormat = '1.0-0',
  ): any {
    let convertedDistance: number;
    let unitSuffix: string = unit;

    if (unit === DistanceUnit.MILLES) {
      displayFormat = '1.1-2';
    }

    if (unit === DistanceUnit.METERS && value > 1000) {
      convertedDistance = value / 1000;
      unitSuffix = 'km';

      if (value < 100000) {
        displayFormat = '1.1-1';
      }
    } else {
      convertedDistance = value;
    }

    return (
      new DecimalPipe(this.localeId).transform(convertedDistance, displayFormat) +
      ' ' +
      unitSuffix
    );
  }
}
