import {Injectable} from '@angular/core';

import {LocalStorage} from './local-storage';

@Injectable()
export class LocalStorageService implements LocalStorage {
  // noinspection JSMethodCanBeStatic
  get length(): number {
    return localStorage.length;
  }

  clear(): void {
    localStorage.clear();
  }

  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  key(index: number): string | null {
    return localStorage.key(index);
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  setItem(key: string, data: string): boolean {
    try {
      localStorage.setItem(key, data);
      return true;
    } catch (e) {
      return false;
    }
  }
}
