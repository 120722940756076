import {Injectable} from '@angular/core';
import {combineLatest, filter, map, Observable, switchMap, tap} from 'rxjs';
import {User} from '../../user/data/user';
import {State} from './state';
import {SessionService} from '../../user/auth/session.service';
import {UserService} from '../../user/data/user.service';
import {StatesService} from './states.service';
import {StatesDao} from './states.dao';
import {StateAppDataService} from './state-app-data.service';

import {environment} from '~environments/environment';
import {Logger} from 'common';

@Injectable()
export class UpdateStateAppListenerService {
  constructor(
    private statesService: StatesService,
    private stateAppDataService: StateAppDataService,
    private sessionService: SessionService,
    private userService: UserService,
    private statesDao: StatesDao,
    // Remove when WEB-3100 completed
    private logger: Logger,
  ) {}

  /**
   * Initialize listener
   */
  init(): void {
    combineLatest([
      this.statesService.getData(),
      this.sessionService.isLoggedIn().pipe(
        switchMap((logged: boolean) =>
          logged
            ? this.userService.getData().pipe(
                filter((user: User) => !!user),
                map((user: User) =>
                  user?.accountStateCode
                    ? user.accountStateCode
                    : environment.geolocation.defaultAnonimousStateCode,
                ),
              )
            : this.statesDao.getStateCodeByIp(),
        ),
      ),
    ] as [Observable<State[]>, Observable<string>])
      .pipe(
        map(([states, stateCode]: [State[], string]) =>
          states.find((state: State) => state.code === stateCode),
        ),
        // Remove after WEB-3100 and relates
        tap(state => {
          if (!state) {
            this.logger.error(
              'WEB-3100: UpdateStateAppListenerService.init: state is null',
            );
          }
        }),
      )
      .subscribe((state: State | null) => this.stateAppDataService.setData(state));
  }
}
