import {
  Directive,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import {ResponsiveService} from '../responsive.service';

@Directive({
  selector: '[tlIfMobile]',
})
export class IfMobileDirective implements OnInit, OnChanges {
  // For avoid warning *tlIfMobile requires value
  @Input()
  tlIfMobile = false;

  @Input()
  tlIfMobileAnd?: boolean;

  @Input()
  tlIfMobileElse?: TemplateRef<any>;

  constructor(
    private responsiveService: ResponsiveService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.renderView();
  }

  ngOnChanges(): void {
    this.renderView();
  }

  private renderView() {
    this.viewContainerRef.clear();

    if (
      this.responsiveService.isMobile() &&
      (this.tlIfMobileAnd === undefined || this.tlIfMobileAnd)
    ) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (this.tlIfMobileElse) {
      this.viewContainerRef.createEmbeddedView(this.tlIfMobileElse);
    }
  }
}
