import {Injectable} from '@angular/core';
import {BoothViewDataService} from './booth-view-data.service';
import {
  tap,
  Observable,
  take,
  switchMap,
  Subject,
  takeUntil,
  first,
  skip,
  finalize,
} from 'rxjs';
import {Logger, AlertsService, PaginationDirection} from 'common';
import {GeolocationViewService} from '../geolocation/geolocation-view.service';
import {GeolocationService} from '../geolocation/geolocation.service';
import {StateAppService} from '../states/state-app.service';
import {Booth} from './data/booth';
import {BoothFilterDataSource} from './data/booth-filter';
import {BoothsRemoteDao} from './data/booths-dao';
import {State} from '../states/data/state';

@Injectable()
export class BoothRemoteViewDataService extends BoothViewDataService {
  constructor(
    protected boothsDao: BoothsRemoteDao,
    protected geolocationService: GeolocationService,
    protected geolocationViewService: GeolocationViewService,
    protected logger: Logger,
    protected alertService: AlertsService,
    protected stateAppService: StateAppService,
  ) {
    super(
      boothsDao,
      geolocationService,
      geolocationViewService,
      logger,
      alertService,
    );
  }

  /** Call Dao again with filters */
  setFilter(filterData: BoothFilterDataSource): void {
    if (this.geolocationSortEnabled) {
      const locationData = this.geolocationBySort$.value.coords;
      filterData = {
        ...filterData,
        coords: {
          long: locationData.longitude,
          lat: locationData.latitude,
        },
      };
    }
    super.setFilter(filterData);
    this.paginationEnd = false;
    this.paginationIndex$.next(0);
    this.getFromDao()
      .pipe(
        tap(newData => {
          this.setData(newData);
        }),
      )
      .subscribe();
  }

  /** Call Dao again with geolocation filter  */
  enableSortByGeolocation(): void {
    super.enableSortByGeolocation();
    this.geolocationBySort$.pipe(skip(1), first()).subscribe(() =>
      this.setFilter({
        ...this.filter$.value,
      }),
    );
  }

  /** Call Dao again without geolocation filter  */
  disableSortByGeolocation(): void {
    super.disableSortByGeolocation();
    this.setFilter({
      ...this.filter$.value,
      coords: null,
    });
  }

  /** Call dao again to get next page */
  loadMore(direction?: PaginationDirection): Observable<Array<Booth>> {
    super.loadMore();
    return this.getFromDao().pipe(
      tap(newData => {
        if (!newData?.length) {
          this.paginationEnd = true;
        }
        this.setData([...this.data$.value, ...newData]);
      }),
    );
  }

  /** Starts the datasource logic ops and return main observable
   *
   * @param destroyRef Must to pass destroy ref to finalize obserbable dataSource */
  initDataSource(destroyRef: Observable<any> | Subject<any>, pageSize: number) {
    this.paginationSize = pageSize;
    if (this.dataSourceSubscription) {
      this.dataSourceSubscription.unsubscribe();
    }
    this.boothsDao.init();
    this.dataSourceSubscription = this.data$
      .pipe(
        takeUntil(destroyRef),
        finalize(() => {
          this.boothsDao.destroy();
          this.dataSourceSubscription = null;
        }),
      )
      .subscribe(data => this.dataSource.next(data));

    if (this.paginationIndex === -1) {
      this.loadMore().subscribe();
    }
    return this.dataSource.asObservable();
  }

  protected getFromDao(): Observable<Booth[]> {
    return this.stateAppService.getState().pipe(
      take(1),
      tap(state => this.boothsDao.updateStateEndpoint(state.code)),
      tap(state => this.updateEmptyLabel(state)),
      switchMap(() => super.getFromDao()),
    );
  }

  private updateEmptyLabel(state: State) {
    this.emptyLabel = {
      key: 'administrationOffice.adminSearchEmptyResultsFarFrom',
      keyData: {
        state: state.name,
      },
    };
  }
}
