import {NgModule} from '@angular/core';
import {VerticalStepperComponent} from './vertical-stepper.component';
import {CommonModule} from '@angular/common';
import {TranslatableTextModule} from '../i18n/translatable-text/translatable-text.module';

@NgModule({
  imports: [CommonModule, TranslatableTextModule],
  declarations: [VerticalStepperComponent],
  exports: [VerticalStepperComponent],
})
export class VerticalStepperModule {}
